import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import MailIcon from '@material-ui/icons/Mail'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import CloseIcon from '@material-ui/icons/Close'
import Zoom from '@material-ui/core/Zoom'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import Slide from 'components/Slide'
import CustomTextField from 'components/CustomTextField'

import mapOpen from 'assets/icons/Map Open.png'
import GoogleMapReact from 'google-map-react'
import { useUser } from 'contexts/userContext'
import { useText } from 'contexts/textContext'

import logo from 'assets/icons/logo-black.png'

const useCloseBarStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		position: 'absolute',
		top: 0,
		left: -17,

		[theme.breakpoints.between(
			theme.breakpoints.values.md,
			theme.breakpoints.values.md + 96
		)]: {
			left: -26,
		},
	},
	inner: {
		height: '100%',
		display: 'flex',
		cursor: 'pointer',
		alignItems: 'center',
		justifyContent: 'center',
	},
	icon: {
		fontWeight: 900,
	},
}))

const CloseBar = ({ onClick }) => {
	const classes = useCloseBarStyles()

	return (
		<div className={classes.root}>
			<div className={classes.inner} onClick={onClick}>
				<CloseIcon className={classes.icon} />
			</div>
		</div>
	)
}

CloseBar.propTypes = {
	onClick: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1, 2),
	},
	container: {
		width: '100%',
	},
	slider: {
		padding: theme.spacing(3),
		backgroundColor: theme.palette.green,
		borderLeft: '16px solid transparent',
		borderRight: '16px solid transparent',
		'& *': {
			color: theme.palette.white,
		},
		[theme.breakpoints.down('md')]: {
			borderLeft: '32px solid transparent',
		},
	},
	bg_blue: {
		backgroundColor: theme.palette.blue,
		'& *': {
			color: theme.palette.yellow,
		},
	},
	bg_yellow: {
		backgroundColor: theme.palette.yellow,
		'& *': {
			color: theme.palette.green,
		},
	},
	bg_green: {
		backgroundColor: theme.palette.green,
		'& *': {
			color: theme.palette.white,
		},
	},
	bl_blue: {
		borderLeftColor: theme.palette.blue,
	},
	bl_yellow: {
		borderLeftColor: theme.palette.yellow,
	},
	bl_green: {
		borderLeftColor: theme.palette.green,
	},
	br_blue: {
		borderRightColor: theme.palette.blue,
	},
	br_yellow: {
		borderRightColor: theme.palette.yellow,
	},
	br_green: {
		borderRightColor: theme.palette.green,
	},
	header: {
		fontSize: theme.typography.h2.fontSize,
		fontWeight: 900,
		textAlign: 'left',
		lineHeight: 1,
	},
	phone: {
		whiteSpace: 'nowrap',
		fontSize: theme.typography.h3.fontSize,
		fontWeight: 900,
		lineHeight: 1,
		textDecoration: 'none',
		backgroundColor: theme.palette.yellow,
		padding: theme.spacing(0.25, 1.5, 0, 1.5),
		color: theme.palette.white,
	},
	location: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		'& > div': {
			fontSize: theme.typography.h4.fontSize,
			textDecoration: 'underline',
			fontWeight: 700,
		},
	},
	content: {
		'& *': {
			color: theme.palette.white,
		},
		overflow: 'auto',
		'& p': {
			marginBottom: theme.spacing(2),
		},
	},
	icon: {
		height: theme.typography.h5.fontSize,
		width: 'auto',
		paddingLeft: theme.spacing(1),
	},
	overMap: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	underMap: {
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	send: {
		color: theme.palette.white,
		fontWeight: 'bold',
		justifySelf: 'flex-end',
		fontSize: theme.typography.body1.fontSize,
		cursor: 'pointer',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			width: '100%',
			backgroundColor: 'rgba(0, 0, 0, 0.12)',
			padding: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
	},
	sendIcon: {
		color: theme.palette.white,
		fontWeight: 'bold',
		fontSize: theme.typography.body1.fontSize,
		cursor: 'pointer',
	},
	mapSidebar: {
		padding: 0,
		margin: 0,
		height: '100%',
		width: 16,
		cursor: 'pointer',
		[theme.breakpoints.down('md')]: {
			width: 32,
		},
	},
	headerBottomPadding: {
		[theme.breakpoints.down('sm')]: {
			paddingBottom: theme.spacing(2),
		},
	},
	centerMe: {
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			justifyContent: 'center',
		},
	},
	h3: {
		fontSize: theme.typography.h3.fontSize,
		fontWeight: 900,
	},
	contactAttributes: {
		fontSize: theme.typography.body1.fontSize,
		textDecoration: 'none',
		textAlign: 'left',
	},
	hours: {
		fontSize: theme.typography.h5.fontSize,
		fontWeight: 'bold',
	},
	hoursLone: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
	},
	hoursPadding: {
		paddingBottom: theme.spacing(5),
		[theme.breakpoints.down('md')]: {
			margin: theme.spacing(5, 0),
		},
	},
	mapContainer: {
		height: '100%',
		width: '100%',
		minHeight: 333,
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			minHeight: 520,
		},
	},
}))

const defaultProps = {
	center: {
		lat: 18.48985624975318,
		lng: -69.98141245024748,
	},
	zoom: 16,
}

const LogoMap = () => (
	<div style={{ cursor: 'pointer' }}>
		<a
			target='_blank'
			rel='noopener noreferrer'
			href='https://g.page/CityStorageRD?share'
		>
			<img src={logo} style={{ width: '8rem', display: 'block' }} />
		</a>
	</div>
)

const inputDefault = {
	firstName: '',
	lastName: '',
	email: '',
	title: '',
	body: '',
}

const requiredInput = [
	{ key: 'firstName', empty: '' },
	{ key: 'lastName', empty: '' },
	{ key: 'email', empty: '' },
	{ key: 'title', empty: '' },
	{ key: 'body', empty: '' },
]

const openDefault = {
	map: false,
	hours: false,
	captcha: false,
}

function Contact() {
	const classes = useStyles()
	const text = useText()
	const { executeRecaptcha } = useGoogleReCaptcha()
	const { enqueueSnackbar } = useSnackbar()
	const user = useUser()

	const [isOpen, setOpen] = React.useState(openDefault)
	const [input, setInput] = React.useState(inputDefault)
	const [loading, setLoading] = React.useState(false)

	React.useEffect(() => {
		if (user) {
			let { displayName, email } = user
			let change = { email }

			if (displayName) {
				displayName = displayName.split(' ')
				const firstName = displayName.slice(0, -1)[0].trim()
				const lastName = displayName.slice(-1)[0].trim()

				change = { ...change, firstName, lastName }
			}

			setInput({
				...inputDefault,
				...change,
			})
		}
	}, [user])

	const toggleOpen = React.useCallback(
		(who) => () => {
			setOpen({ ...isOpen, [who]: !isOpen[who] })
		},
		[isOpen]
	)

	const handleChange = React.useCallback(
		(key) => (e) => {
			setInput({ ...input, [key]: e.target.value })
		},
		[input]
	)

	const onSubmit = React.useCallback(() => {
		const token = executeRecaptcha()

		if (requiredInput.some((r) => input[r.key]?.trim() === r.empty)) {
			enqueueSnackbar(text.error_empty_fields, {
				variant: 'error',
				preventDuplicate: true,
			})
		} else {
			setLoading(true)
			token
				.then((resp) => {
					axios
						.post(
							`${process.env['REACT_APP_SEND_EMAIL']}?type=contact&token=${resp}`,
							{
								firstName: input.firstName.trim(),
								lastName: input.lastName.trim(),
								from: input.email.toLowerCase().trim(),
								title: input.title.trim(),
								body: input.body.trim(),
							}
						)
						.then(() => {
							setInput({ ...input, title: '', body: '' })
							enqueueSnackbar(text.send_email, {
								variant: 'success',
								preventDuplicate: true,
							})
						})
						.catch((error) => {
							enqueueSnackbar(text.error_generic, {
								variant: 'error',
								preventDuplicate: true,
							})
							console.log(error)
						})
						.finally(() => {
							setLoading(false)
						})
				})
				.catch((error) => {
					enqueueSnackbar(text.error_generic, {
						variant: 'error',
						preventDuplicate: true,
					})
					console.log(error)
					setLoading(false)
				})
		}
	}, [executeRecaptcha, enqueueSnackbar, text, input])

	return (
		<Slide
			disablePadding={isOpen.map}
			colors={['bg_red', 'bl_red', 'br_green']}
		>
			<div
				className={clsx(!isOpen.map && classes.root)}
				style={{ height: '100%', width: '100%' }}
			>
				{isOpen.map && (
					<div className={classes.mapContainer}>
						<CloseBar onClick={toggleOpen('map')} />

						<GoogleMapReact
							bootstrapURLKeys={{
								key: 'AIzaSyBKM1HLvS05NbccGveV4PxhIB6yH4s8Bf0',
							}}
							defaultCenter={defaultProps.center}
							defaultZoom={defaultProps.zoom}
							// onChildClick={(id) => console.log(id)}
							style={{ height: '100%', width: '100%' }}
						>
							<LogoMap lat={18.48985624975318} lng={-69.98141245024748} />
						</GoogleMapReact>
					</div>
				)}
				{!isOpen.map && (
					<Grid container justify='space-between' style={{ height: '100%' }}>
						<Grid
							item
							xs={12}
							md={6}
							container
							alignItems='flex-end'
							justify='flex-start'
							className={clsx(classes.centerMe, classes.headerBottomPadding)}
							spacing={2}
						>
							<Grid item xs={12}>
								<Grid
									container
									alignItems='flex-end'
									className={classes.centerMe}
									spacing={1}
								>
									<Grid
										item
										xs={12}
										md='auto'
										className={clsx(classes.header, classes.centerMe)}
									>
										{text.contact}
									</Grid>
									<Grid
										item
										xs={12}
										md='auto'
										className={classes.centerMe}
										style={{ paddingTop: 4 }}
									>
										<a href='tel:+18095644284' className={classes.phone}>
											{'(809) 564-4284'}
										</a>
									</Grid>
									<Grid item xs={12}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<LocationOnIcon style={{ marginRight: 8 }} />
											<a
												href='https://g.page/CityStorageRD?share'
												className={clsx(classes.contactAttributes)}
											>
												Autopista Juan Pablo Duarte km 10
												<br />
												Santo Domingo 10607
											</a>
										</div>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<MailIcon style={{ marginRight: 8 }} />
											<a
												href='mailto:info@citystorage.com.do'
												className={clsx(classes.contactAttributes)}
											>
												info@citystorage.com.do
											</a>
										</div>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<div
											onClick={toggleOpen('hours')}
											className={clsx(classes.location, classes.overMap)}
										>
											<div>{text.hours}</div>
										</div>
									</Grid>
									<Grid item xs={12}>
										<div
											onClick={toggleOpen('map')}
											className={clsx(classes.location, classes.overMap)}
										>
											<div>{text.location}</div>
											<img src={mapOpen} className={classes.icon} />
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
							md={6}
							container
							justify='flex-end'
							alignItems='center'
							spacing={1}
							style={{ height: '100%' }}
						>
							{isOpen.hours ? (
								<Zoom in={isOpen.hours} style={{ height: '100%' }}>
									<Grid
										item
										container
										alignItems='center'
										className={classes.hoursPadding}
									>
										<Grid item container>
											<Grid
												item
												xs={12}
												className={classes.hoursLone}
												onClick={toggleOpen('hours')}
											>
												<div className={classes.h3}>{text.hours_header}</div>
												<CloseIcon
													style={{ padding: 4 }}
													className={classes.h3}
												/>
											</Grid>
											<Grid item xs={12} className={clsx(classes.hours)}>
												{text.hours_displayed1}
											</Grid>
											<Grid item xs={12} className={clsx(classes.hours)}>
												{text.hours_displayed2}
											</Grid>
										</Grid>
									</Grid>
								</Zoom>
							) : (
								<>
									<Grid item xs={12} sm={6}>
										<CustomTextField
											placeholder={text.name}
											value={input.firstName}
											onChange={handleChange('firstName')}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<CustomTextField
											placeholder={text.lastName}
											value={input.lastName}
											onChange={handleChange('lastName')}
										/>
									</Grid>
									<Grid item xs={12}>
										<CustomTextField
											placeholder={text.email}
											value={input.email}
											onChange={handleChange('email')}
										/>
									</Grid>
									<Grid item xs={12}>
										<CustomTextField
											placeholder={text.title}
											value={input.title}
											onChange={handleChange('title')}
										/>
									</Grid>
									<Grid item xs={12}>
										<CustomTextField
											placeholder={text.body}
											multiline
											rows={8}
											value={input.body}
											onChange={handleChange('body')}
										/>
									</Grid>
								</>
							)}
							<Grid
								item
								container
								justify='space-between'
								alignItems='center'
								spacing={1}
							>
								{!isOpen.hours && (
									<Grid item xs={12} md='auto'>
										<Grid
											container
											alignItems='center'
											className={classes.send}
											onClick={e => {
												if (!loading) onSubmit(e)
											}}
										>
											{text.send} {loading ? <CircularProgress size={20} style={{ marginLeft: 8 }} /> : <NavigateNextIcon />}
										</Grid>
									</Grid>
								)}
								<Grid item>
									<div
										onClick={toggleOpen('map')}
										className={clsx(classes.location, classes.underMap)}
									>
										<div>{text.location}</div>
										<img src={mapOpen} className={classes.icon} />
									</div>
								</Grid>
								<Grid item>
									<div
										onClick={toggleOpen('hours')}
										className={clsx(classes.location, classes.underMap)}
									>
										<div>{text.hours}</div>
									</div>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
			</div>
		</Slide>
	)
}

export default Contact
