import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { scrollTo } from 'assets/js/utils'
import { useText } from 'contexts/textContext'

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 144 + 64,
	},
	container: {
		backgroundColor: theme.palette.white,
		padding: theme.spacing(4,2)
	},
	send: {
		color: theme.palette.black,
		fontWeight: 'bold',
		justifySelf: 'flex-end',
		fontSize: theme.typography.body1.fontSize,
		cursor: 'pointer',
	},
}))


const useStyleTextField = makeStyles((theme) => ({
	root: {
		color: `${theme.palette.black} !important`,
		border: `1px solid ${theme.palette.black} !important`,
		overflow: 'hidden',
		borderRadius: 0,
		backgroundColor: 'transparent',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: 0,
			},
			'&:hover fieldset': {
				border: 0,
			},
			'&.Mui-focused fieldset': {
				backgroundColor: 'transparent',
				border: 0,
			},
		},
	},
}))

function CustomTextField(props) {
	const classes = useStyleTextField()

	return (
		<TextField
			fullWidth
			margin='dense'
			variant='outlined'
			// disableClearable={true}
			classes={{ root: classes.root }}
			InputProps={{ classes }}
			{...props}
		/>
	)
}

const inputDefault = { email: '', title: '', body: '' }

function Suggestions() {
	const classes = useStyles()
	const text = useText()

	const [input, setInput] = React.useState(inputDefault)

	React.useEffect(() => {
		scrollTo('#a', 0, 500)
	}, [])

	const handleInput = React.useCallback(key => e => {
		setInput({ ...input, [key]: e.target.value })
	}, [input])

	return (
		<Container maxWidth='sm' className={classes.root}>
			<div className={classes.container}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<CustomTextField placeholder={text.email} value={input.email} onChange={handleInput('email')} />
					</Grid>
					<Grid item xs={12}>
						<CustomTextField placeholder={text.title} value={input.title} onChange={handleInput('title')} />
					</Grid>
					<Grid item xs={12}>
						<CustomTextField placeholder={text.body} multiline rows={8} value={input.body} onChange={handleInput('body')} />
					</Grid>
					<Grid item container justify='flex-end' alignItems='center'>
						<Grid item>
							<Grid
								container
								justify='flex-end'
								alignItems='center'
								className={classes.send}
							>
								{text.send}{' '}<NavigateNextIcon />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</Container>
	)
}

export default Suggestions
