import React, { useContext, createContext } from 'react'
import { useLanguage } from 'contexts/languageContext'
import PropTypes from 'prop-types'
import _text from 'assets/json/text.json'

const textContext = createContext()

export function ProvideText({ children }) {
	const text = useProvideText()
	return (
		<textContext.Provider value={text}>{children}</textContext.Provider>
	)
}

ProvideText.propTypes = {
	children: PropTypes.object.isRequired,
}

const getText = (text, language) =>
	Object.keys(text).reduce((obj, k) => ({ ...obj, [k]: text[k][language] }), {})

const useProvideText = () => {
	const { language } = useLanguage()
	const text = React.useMemo(() => getText(_text, language), [language])

	return text
}

export const useText = () => {
	return useContext(textContext)
}
