import React from 'react'
import gif from 'assets/gifs/loading.gif'
import logo from 'assets/icons/logo.png'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		// marginTop: '24rem',
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	logo: {
		width: '20rem',
		height: 'auto',
		paddingBottom: theme.spacing(1),
	},
	giffy: {
		width: '21rem',
		height: 'auto',
	},
}))

function Loader() {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<div>
				<img src={logo} className={classes.logo} />
			</div>
			<div>
				<img src={gif} className={classes.giffy} />
			</div>
		</div>
	)
}

export default Loader
