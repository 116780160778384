import React from 'react'
import PropTypes from 'prop-types'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { useDispatch } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import clsx from 'clsx'
import { useSnackbar } from 'notistack'

import { updateListValues, deleteListValues } from 'store/actions/chooser'
import { getCurrentSize, getCurrentUnit, units } from 'components/Chooser/utils'
import CustomTextField from 'components/CustomTextField'
import CustomTooltip from 'components/Chooser/CustomTooltip'
import useChooserOptions from 'hooks/useChooserOptions'
import { useText } from 'contexts/textContext'

import ArrowBlack from 'assets/icons/arrow_black.png'
import ArrowWhite from 'assets/icons/arrow_white.png'

const useStyles = makeStyles((theme) => ({
	buttonTab: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: 'transparent',
		border: `1px solid ${theme.palette.white}`,
		width: '100%',
		'& > div': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '2px 4px 4px 2px',
			borderTop: '2px solid transparent',
			borderLeft: '2px solid transparent',
			whiteSpace: 'nowrap',
			fontSize: theme.typography.body2.fontSize,
			width: '100%',
		},
		'&:hover': {
			backgroundColor: theme.palette.white,
			'& *': {
				color: theme.palette.green,
			},
			'& > div': {
				borderTop: `2px solid ${theme.palette.green}`,
				borderLeft: `2px solid ${theme.palette.green}`,
			},
		},
	},
	buttonTab_darkMode: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: 'transparent',
		border: `1px solid ${theme.palette.white} !important`,
		width: '100%',
		'& > div': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '2px 4px 4px 2px',
			borderTop: '2px solid transparent !important',
			borderLeft: '2px solid transparent !important',
			whiteSpace: 'nowrap',
			fontSize: theme.typography.body2.fontSize,
			width: '100%',
		},
		'&:hover': {
			backgroundColor: theme.palette.black,
			'& *': {
				color: `${theme.palette.white} !important`,
			},
			'& > div': {
				borderTop: `2px solid ${theme.palette.white} !important`,
				borderLeft: `2px solid ${theme.palette.white} !important`,
			},
		},
	},
	selected_buttonTab: {
		backgroundColor: theme.palette.white,
		color: theme.palette.green,
		'& *': {
			color: theme.palette.green,
		},
		'& > div': {
			'& *': {
				color: theme.palette.green,
			},
			color: theme.palette.green,
			borderTop: `2px solid ${theme.palette.green}`,
			borderLeft: `2px solid ${theme.palette.green}`,
		},
	},
	selected_buttonTab_darkMode: {
		backgroundColor: theme.palette.black,
		color: `${theme.palette.white} !important`,
		'& *': {
			color: `${theme.palette.white} !important`,
		},
		'& > div': {
			'& *': {
				color: `${theme.palette.white} !important`,
			},
			color: `${theme.palette.white} !important`,
			borderTop: `2px solid ${theme.palette.white} !important`,
			borderLeft: `2px solid ${theme.palette.white} !important`,
		},
	},
	header: {
		fontSize: theme.typography.h2.fontSize,
		fontWeight: 900,
		lineHeight: 1,
		'& span': {
			display: 'block',
		},
		[theme.breakpoints.only('sm')]: {
			textAlign: 'center',
			'& span': {
				display: 'inline-block',
			},
		},
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
			'& span': {
				display: 'block',
			},
		},
	},
	boxPadding: {
		padding: theme.spacing(3, 0, 1.2, 0),
	},
	calculator: {
		border: `1px solid ${theme.palette.white}`,
		padding: theme.spacing(1, 1),
	},
	root: {
		height: '100% !important',
	},
	text: {
		color: theme.palette.white,
		fontSize: theme.typography.body2.fontSize - 2,
		padding: theme.spacing(1.5),
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	left: {
		backgroundColor: theme.palette.yellow,
	},
	right: {
		backgroundColor: theme.palette.red,
		fontWeight: 900,
		fontSize: theme.typography.h6.fontSize,
		cursor: 'pointer',
		'&:hover': {
			color: theme.palette.yellow,
		},
	},
	darkMode: {
		color: `${theme.palette.black} !important`,
		borderColor: `${theme.palette.black} !important`,
		'*': {
			color: `${theme.palette.black} !important`,
			borderColor: `${theme.palette.black} !important`,
		},
		'& *': {
			color: `${theme.palette.black} !important`,
			borderColor: `${theme.palette.black} !important`,
		},
		'& > *': {
			color: `${theme.palette.black} !important`,
			borderColor: `${theme.palette.black} !important`,
		},
	},
	boxContainer: {
		paddingRight: 2,
		height: 64,
	},
	blackBox: {
		border: `1px solid ${theme.palette.black}`,
	},
	whiteBox: {
		border: `1px solid ${theme.palette.white}`,
	},
	dynamicBox: {
		width: 0,
		backgroundColor: theme.palette.yellow,
		transition: '.5s',
	},
	bg1: {
		backgroundColor: theme.palette.yellow,
	},
	bg2: {
		backgroundColor: theme.palette.blue,
	},
	bg3: {
		backgroundColor: theme.palette.darkRed,
	},
	bg4: {
		backgroundColor: theme.palette.darkBlue,
	},
	bg5: {
		backgroundColor: theme.palette.darkYellow,
	},
	bg6: {
		backgroundColor: theme.palette.red,
	},
	bg7: {
		backgroundColor: theme.palette.black,
	},
	moreButton: {
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: theme.typography.body2.fontSize,
	},
	h4: {
		fontSize: theme.typography.h4.fontSize,
	},
	body1: {
		fontSize: theme.typography.body1.fontSize,
	},
	body2: {
		fontSize: theme.typography.body2.fontSize,
	},
	tableListName: {
		marginRight: '1rem',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		fontSize: theme.typography.body1.fontSize,
	},
	tableDimensions: {
		fontSize: theme.typography.body2.fontSize - 3,
		lineHeight: theme.typography.body2.lineHeight - 0.5,
		color: theme.palette.white,
	},
	customCreateButton: {
		width: '100%',
		padding: theme.spacing(1, 3),
		fontSize: theme.typography.body1.fontSize,
		cursor: 'pointer',
		backgroundColor: 'rgba(0, 0, 0, 0.12)',
	},

	dimensions: {
		fontSize: theme.typography.caption.fontSize,
		color: 'gray',
	},
	listName: {
		marginRight: '1rem',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		fontSize: theme.typography.body2.fontSize,
	},
	darkInput: {
		'& .MuiOutlinedInput-root': {
			borderColor: `${theme.palette.black} !important`,
		},
	},
	lightInput: {
		'& .MuiOutlinedInput-root': {
			borderColor: `${theme.palette.white} !important`,
		},
	},
}))

function ChooserAutocomplete({
	listValues,
	tutorial,
	setTutorial,
	unit,
	setUnit,
	i,
	enableDarkMode,
}) {
	const dispatch = useDispatch()
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const {
		customOptions,
		options,
		parsedOptions,
		filteringOptions,
	} = useChooserOptions()
	const text = useText()

	const [value, setValue] = React.useState('')

	const setListValues = React.useCallback(
		(newListValues) => {
			dispatch(updateListValues(newListValues))
		},
		[dispatch]
	)

	const handleDelete = React.useCallback(
		(i) => {
			dispatch(deleteListValues(i))
		},
		[dispatch]
	)

	const handleChange = React.useCallback(
		(_, selected) => {
			let newListValues = listValues.slice()
			let newVals = newListValues[unit].slice()

			if (tutorial === 1) setTutorial(2)

			newVals.push({ ...selected, count: 1 })

			if (getCurrentSize(newVals) > getCurrentUnit(newVals)) {
				const nonFullUnitIndex = newListValues.findIndex(
					(vals) =>
						getCurrentSize([...vals, { ...selected, count: 1 }]) <=
						units[units.length - 1]
				)

				if (nonFullUnitIndex !== -1) {
					let nonFullUnit = newListValues[nonFullUnitIndex].slice()

					nonFullUnit.push({ ...selected, count: 1 })
					newListValues[nonFullUnitIndex] = nonFullUnit

					setListValues(newListValues)
				} else if (newListValues.length < 3) {
					newListValues.push([{ ...selected, count: 1 }])
					setListValues(newListValues)
				} else {
					enqueueSnackbar(text.warehouses_full, {
						variant: 'warning',
						preventDuplicate: true,
					})
				}
			} else {
				newListValues[unit] = newVals
				setListValues(newListValues)
			}

			setValue('')
		},
		[
			unit,
			listValues,
			setListValues,
			enqueueSnackbar,
			tutorial,
			setTutorial,
			text,
		]
	)

	return (
		<Autocomplete
			onFocus={() => {
				setUnit(i)
				setValue('')
			}}
			onBlur={() => setValue('')}
			autoComplete
			filterSelectedOptions
			clearOnBlur
			// disableClearable
			noOptionsText={text.empty_search}
			options={[...customOptions, ...parsedOptions]}
			renderOption={(r) => {
				if (r.name === 'custom') {
					return (
						<div style={{ width: '100%' }}>
							<Divider style={{ width: '100%' }} />
							<div className={classes.listName}>{text.custom_item_header}</div>
							<Divider style={{ width: '100%' }} />
						</div>
					)
				}

				if (Object.keys(options).includes(r.name)) {
					return (
						<div style={{ width: '100%' }}>
							<Divider style={{ width: '100%' }} />
							<div className={classes.listName}>{r.name}</div>
							<Divider style={{ width: '100%' }} />
						</div>
					)
				}

				return (
					<div style={{ width: '100%' }}>
						<div className={classes.listName}>{r.name}</div>
						<div className={classes.dimensions}>
							{`${r.length}" x ${r.width}" x ${r.height}" (${text.inches})`}
						</div>
					</div>
				)
			}}
			filterOptions={(_, state) => {
				const userInput = state.inputValue.toLowerCase()
				const newOpts = filteringOptions.filter((option) => {
					let parent = option.parent.toLowerCase()
					// allows for translation of custom items header
					// e.g.:	user types "custom measu"
					// 				now we can match it to the translated
					// 				custom item header text.custom_item_header
					// 				AND it will only match the correct language
					if (parent === 'custom')
						parent = text.custom_item_header.toLowerCase()

					const name = option.name.toLowerCase()
					const ret = parent.includes(userInput) || name.includes(userInput)

					return ret
				})

				let seenParents = []
				let ret = []

				for (let opt of newOpts) {
					if (!seenParents.includes(opt.parent)) {
						let parentItem = { name: opt.parent }

						ret.push(parentItem)
						seenParents.push(parentItem.name)
					}

					ret.push(opt)
				}

				return ret
			}}
			getOptionLabel={(r) => r.name}
			getOptionDisabled={(r) =>
				['custom', text.custom_item_header, ...Object.keys(options)].includes(
					r.name
				) || listValues[unit].some((o) => o.name === r.name)
			}
			onChange={handleChange}
			onInputChange={(_, value) => setValue(value)}
			inputValue={unit === i ? value : ''}
			renderInput={(params) => (
				<CustomTextField
					{...params}
					placeholder={`${text.calculator_placeholder} ${i + 1}`}
					style={{ margin: 0, marginTop: 8, padding: 0 }}
					className={clsx(
						enableDarkMode ? classes.darkInput : classes.lightInput
					)}
					// disableClearable
					InputProps={{
						...params.InputProps,
						style: {
							paddingRight: 8,
							cursor: 'pointer',
						},
						startAdornment: (
							<InputAdornment onClick={() => params.inputProps.onMouseDown()}>
								<CustomTooltip
									open={tutorial === 1 && i === 0}
									placement='top-start'
									title={text.tooltip_add_item_warehouse}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: 42,
											width: 18,
											padding: '0px 6px',
											borderRight: `1px solid ${enableDarkMode ? 'black' : 'white' }`,
										}}
										>
										<img
											src={enableDarkMode ? ArrowBlack : ArrowWhite}
											style={{
												width: '100%',
												paddingRight: 5,
											}}
										/>
									</div>
									{/* <ArrowDropDownIcon
										style={{
											borderRight: '1px solid white',
											paddingRight: 2,
											fontSize: 32,
										}}
									/> */}
								</CustomTooltip>
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment>
								<CustomTooltip
									open={tutorial === 4 && i === 0}
									placement='left'
									title={text.tooltip_remove_warehouse}
								>
									<IconButton
										disabled={listValues.length === 1 && i === 0}
										style={{
											padding: 0,
											color:
												listValues.length === 1 && i === 0 ? 'gray' : 'black',
										}}
										onClick={() => {
											if (i === 0 && tutorial === 4) setTutorial(5)
											handleDelete(i)
										}}
									>
										<DeleteIcon fontSize='large' />
									</IconButton>
								</CustomTooltip>
							</InputAdornment>
						),
					}}
				/>
			)}
		/>
	)
}

ChooserAutocomplete.propTypes = {
	listValues: PropTypes.any,
	tutorial: PropTypes.number.isRequired,
	setTutorial: PropTypes.func.isRequired,
	unit: PropTypes.number.isRequired,
	setUnit: PropTypes.func.isRequired,
	i: PropTypes.number.isRequired,
	enableDarkMode: PropTypes.bool,
}

export default ChooserAutocomplete
