import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useRoutes } from 'contexts/routesContext'


const getCurrentTab = (pathname, routes, exact) => {
	let route = routes.filter((x) => {
		const _path = exact ? pathname.slice(0, x.path.length) : pathname
		const match = x.path === _path

		return match
	})[0]

	if (route) return route.path
	return false
}

function useCurrentLocation(exact) {
	const { pathname, hash } = useLocation()
	const { routes } = useRoutes()

	const [tab, setTab] = useState(false)
	
	useEffect(() => {
		setTab(getCurrentTab(pathname, routes, exact))
	}, [pathname, routes, exact])

	return [tab, hash]
}

export default useCurrentLocation
