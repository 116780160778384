import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'

import { addDefaultValue } from 'store/actions/chooser'
import { useDispatch, useSelector } from 'react-redux'

import { labels, units } from 'assets/js/constants'

const CustomSlider = withStyles((theme) => ({
	root: {
		backgroundColor: 'transparent',
	},
	mark: {
		color: theme.palette.red,
		height: 10,
		marginTop: -4,
		'&:focus, &:hover, &$active': {
			color: theme.palette.red,
		},
	},
	thumb: {
		height: 24,
		width: 24,
		backgroundColor: theme.palette.white,
		borderRadius: '100%',
		border: `7px solid ${theme.palette.red}`,
		marginTop: -11,
		marginLeft: -11,
		'&:focus, &:hover, &$active': {
			outline: 'none',
			color: theme.palette.red,
			boxShadow: 'none',
		},
	},
	active: {
		color: theme.palette.red,
	},
	track: {
		color: theme.palette.red,
		opacity: 1,
		height: 2,
	},
	rail: {
		color: theme.palette.red,
		opacity: 1,
		height: 2,
	},
}))(Slider)

const useStylesTooltip = makeStyles((theme) => ({
	parent: {
		position: 'absolute',
	},
	child: {
		color: theme.palette.white,
		marginLeft: '-75%',
		textAlign: 'center',
		marginTop: -32,
		fontWeight: 'bold',
	}
}))

function ValueLabelComponent(props) {
	const { children, value } = props
	const classes = useStylesTooltip()

	return (
		<div style={{ position: 'relative' }}>
			<div style={children.props.style} className={classes.parent}>
				<div className={classes.child}>{value}</div>
			</div>
			<div>{children}</div>
		</div>
	)
}

ValueLabelComponent.propTypes = {
	children: PropTypes.element.isRequired,
	open: PropTypes.bool.isRequired,
	value: PropTypes.number.isRequired,
}

function UnitSliderBar() {
	const [defaultValue] = useSelector(state => [state.chooser.defaultValue])
	const dispatch = useDispatch()

	const handleChange = React.useCallback((_, newValue) => {
		console.log(newValue)
		dispatch(addDefaultValue(newValue))
	}, [dispatch])

	return (
		<CustomSlider
			ValueLabelComponent={ValueLabelComponent}
			valueLabelFormat={(i) => `${units[i]}m²`}
			value={defaultValue}
			onChange={handleChange}
			step={1}
			min={0}
			marks
			max={labels.length - 1}
			valueLabelDisplay='on'
		/>
	)
}

export default UnitSliderBar
