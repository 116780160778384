import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'


const useStyleTextField = makeStyles((theme) => ({
	root: {
		border: 0,
		overflow: 'hidden',
		borderRadius: 0,
		backgroundColor: 'transparent',
		margin: 0,
		'& .MuiOutlinedInput-root': {
			border: `1px solid ${theme.palette.white} !important`,
			borderRadius: 0,
			'& fieldset': {
				border: 0,
			},
			'&:hover fieldset': {
				border: 0,
			},
			'&.Mui-focused fieldset': {
				backgroundColor: 'transparent',
				border: 0,
			},
		},
	},
}))

function CustomTextField(props) {
	const classes = useStyleTextField()

	return (
		<TextField
			fullWidth
			margin='dense'
			variant='outlined'
			classes={{ root: classes.root }}
			InputProps={{ classes }}
			{...props}
		/>
	)
}

export default CustomTextField