import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'
import Collapse from '@material-ui/core/Collapse'
import CloseIcon from '@material-ui/icons/Close'
import { BANNER_HEIGHT } from 'assets/js/constants'
import { useText } from 'contexts/textContext'

const useStyles = makeStyles((theme) => ({
	outer: {
		width: '100%',
		backgroundColor: theme.palette.green,
		height: BANNER_HEIGHT,
	},
	close: {
		color: theme.palette.white,
	},
	text: {
		color: theme.palette.white,
		fontSize: theme.typography.button.fontSize,
		textTransform: 'uppercase',
	},
}))

function Banner(props) {
	const classes = useStyles()
	const { banner } = useText()

	return (
		<Collapse in={props.isShowing}>
			<div className={classes.outer}>
				<Container maxWidth='sm' style={{ height: '100%' }}>
					<Grid
						container
						direction='row'
						spacing={1}
						alignItems='center'
						justify='center'
						wrap='nowrap'
						style={{ height: '100%' }}
					>
						<Grid item>
							<IconButton
								disableRipple
								disableFocusRipple
								disableTouchRipple
								style={{ backgroundColor: 'transparent', padding: 0 }}
								onClick={() => props.setShowing(false)}
							>
								<CloseIcon className={classes.close} />
							</IconButton>
						</Grid>
						<Grid item className={classes.text}>
							{/* <span style={{ fontWeight: 'bold' }}>Banner - </span> */}
							{banner}
						</Grid>
					</Grid>
				</Container>
			</div>
		</Collapse>
	)
}

Banner.propTypes = {
	isShowing: PropTypes.bool.isRequired,
	setShowing: PropTypes.func.isRequired,
}

export default Banner
