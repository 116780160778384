import React from 'react'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

import { scrollTo } from 'assets/js/utils'
import { useFAQ } from 'contexts/faqContext'
import { useText } from 'contexts/textContext'

const useStyles = makeStyles((theme) => ({
	root: {
		'& h4': {
			fontSize: theme.typography.h4.fontSize,
		},
	},
	container: {
		backgroundColor: theme.palette.white,
		marginTop: '16rem',
		padding: theme.spacing(12, 4, 12, 4),
	},
	divider: {
		width: '100%',
		margin: theme.spacing(1, 0),
	},
	content: {
		fontSize: theme.typography.body1.fontSize,
		'& *': {
			fontSize: theme.typography.body1.fontSize,
		},
		'& > ul': {
			listStyleType: 'decimal',
			margin: theme.spacing(0, 3),
			'& > li': {
				margin: theme.spacing(1.5, 0),
			},
		},
		'& p': {
			margin: theme.spacing(1.5, 0),
		},
		'& b': {
			fontWeight: 'bold',
		},
		'& a': {
			color: 'black',
		},
	},
}))

const Accordion = withStyles((theme) => ({
	root: {
		borderTop: `1px solid ${theme.palette.gray}`,
		boxShadow: 'none',
		'&:first-child': {
			borderTop: 0,
		},
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
}))(MuiAccordion)

const AccordionSummary = withStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.footer,
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
			fontWeight: 'bold',
		},
	},
	expanded: {},
}))(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		backgroundColor: theme.palette.footer,
	},
}))(MuiAccordionDetails)

function FAQ() {
	const classes = useStyles()
	const faq = useFAQ()
	const text = useText()

	React.useEffect(() => {
		scrollTo('#a', 0, 500)
	}, [])

	const [expanded, setExpanded] = React.useState(false)

	const handleChange = (panel) => (_, newExpanded) =>
		setExpanded(newExpanded ? panel : false)

	return (
		<Container maxWidth='md' className={classes.root}>
			<div className={classes.container}>
				<h4>{text.faq}</h4>
				<Divider className={classes.divider} />

				<div className={classes.content}>
					{faq.map((item, i) => (
						<Accordion
							key={i}
							square
							expanded={expanded === i}
							onChange={handleChange(i)}
						>
							<AccordionSummary>
								{`${i + 1}. `}
								{item.question}
							</AccordionSummary>
							<AccordionDetails>{item.answer}</AccordionDetails>
						</Accordion>
					))}
				</div>
			</div>
		</Container>
	)
}

export default FAQ
