import React from 'react'
import Grid from '@material-ui/core/Grid'

import moment from 'moment'
import Container from '@material-ui/core/Container'
import Chooser from 'components/Chooser/Chooser'

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'

import InputAdornment from '@material-ui/core/InputAdornment'
import EventIcon from '@material-ui/icons/Event'
import { useSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { scrollTo } from 'assets/js/utils'
import { useText } from 'contexts/textContext'
import { useUser } from 'contexts/userContext'
import Confirmation from 'pages/Confirmation'

import axios from 'axios'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useLanguage } from 'contexts/languageContext'
import { isValidPhone, isValidEmail } from 'assets/js/utils'

const useStyleTextField = makeStyles((theme) => ({
	root: {
		color: `${theme.palette.black} !important`,
		border: `1px solid ${theme.palette.black} !important`,
		overflow: 'hidden',
		borderRadius: 0,
		backgroundColor: 'transparent',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: 0,
			},
			'&:hover fieldset': {
				border: 0,
			},
			'&.Mui-focused fieldset': {
				backgroundColor: 'transparent',
				border: 0,
			},
		},
	},
}))

function CustomTextField(props) {
	const classes = useStyleTextField()

	return (
		<TextField
			fullWidth
			margin='dense'
			variant='outlined'
			// disableClearable={true}
			classes={{ root: classes.root }}
			InputProps={{ classes }}
			{...props}
		/>
	)
}

const useStyleDate = makeStyles((theme) => ({
	anotherRoot: {
		paddingTop: 7,
		paddingBottom: 4,
	},
	root: {
		color: `${theme.palette.black} !important`,
		border: `2px solid ${theme.palette.black} !important`,
		overflow: 'hidden',
		borderRadius: 0,
		paddingTop: 4.5,
		paddingBottom: 4.5,
		paddingLeft: '1.2rem',
		paddingRight: '1.2rem',
		backgroundColor: 'transparent',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: 0,
			},
			'&:hover fieldset': {
				border: 0,
			},
			'&.Mui-focused fieldset': {
				backgroundColor: 'transparent',
				border: 0,
			},
		},
	},
}))

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.white,
	},
	container: {
		maxWidth: theme.breakpoints.values.sm,
		paddingBottom: theme.spacing(4),
	},
	chooserContainer: {
		marginTop: '5rem',
	},
	makeSpace: {
		marginTop: '16rem',
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: theme.spacing(12),
		paddingBottom: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: theme.spacing(8),
		},
	},
	title: {
		fontSize: theme.typography.h2.fontSize,
		textAlign: 'center',
		fontWeight: 900,
	},
}))

const inputDefault = {
	firstName: '',
	lastName: '',
	email: '',
	dob: null,
	cel: '',
	phone: '',
	location: 'Santo Domingo',
}
const requiredInput = [
	{ key: 'firstName', empty: '' },
	{ key: 'lastName', empty: '' },
	{ key: 'email', empty: '' },
	{ key: 'cel', empty: '' },
	{ key: 'location', empty: '' },
]

function Quote() {
	const language = useLanguage()
	const { enqueueSnackbar } = useSnackbar()
	const text = useText()
	const user = useUser()
	const { executeRecaptcha } = useGoogleReCaptcha()

	const textFieldClasses = useStyleDate()
	const classes = useStyles()
	const [input, setInput] = React.useState(inputDefault)
	const [isConfirm, setConfirm] = React.useState(false)
	const [loading, setLoading] = React.useState(false)

	const handleConfirm = () => {
		setConfirm(true)
		setTimeout(() => setConfirm(false), 15000)
	}

	React.useEffect(() => {
		scrollTo('#a', 0, 500)
	}, [])

	React.useEffect(() => {
		if (user) {
			let { displayName, email, phoneNumber } = user
			let change = { email }

			if (displayName) {
				displayName = displayName.split(' ')
				const firstName = displayName.slice(0, -1)[0].trim()
				const lastName = displayName.slice(-1)[0].trim()

				change = { ...change, firstName, lastName }
			}

			if (phoneNumber) {
				const x = phoneNumber
					.replace(/\D/g, '')
					.match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
				const cel = !x[2]
					? x[1]
					: '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')

				change = { ...change, cel }
			}

			setInput({
				...inputDefault,
				...change,
			})
		}
	}, [user])

	const handleChange = React.useCallback(
		(key) => (e) => {
			const value = e.target?.value ?? e
			let newInput = { ...input, [key]: value }

			if (['phone', 'cel'].includes(key)) {
				const x = newInput[key]
					.replace(/\D/g, '')
					.match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
				newInput[key] = !x[2]
					? x[1]
					: '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
			}

			setInput(newInput)
		},
		[input]
	)

	const handleSubmit = React.useCallback(
		(storages, sizes) => {
			const token = executeRecaptcha()
			const hasItems = storages.some(storage => storage.length > 0)
			console.log(input)

			if (
				requiredInput.some((r) => input[r.key]?.trim() === r.empty) ||
				!isValidPhone(input.cel) ||
				!isValidEmail(input.email) ||
				!hasItems
			) {
				enqueueSnackbar(text.error_empty_fields, {
					variant: 'error',
					preventDuplicate: true,
				})
			} else {
				setLoading(true)
				token
					.then((resp) => {
						axios
							.post(
								`${process.env['REACT_APP_SEND_EMAIL']}?type=confirm&token=${resp}`,
								{
									to: input.email,
									input: { ...input, dob: input.dob || '' },
									storages,
									sizes,
									language,
								}
							)
							.then(() => {
								handleConfirm()
							})
							.catch((error) => {
								console.log(error)
							})
							.finally(() => {
								setLoading(false)
							})
					})
					.catch((error) => {
						console.log(error)
						setLoading(false)
					})
			}
		},
		[
			input,
			enqueueSnackbar,
			text,
			executeRecaptcha,
			language,
		]
	)

	if (isConfirm)
		return <Confirmation email={input.email.trim().toLowerCase()} />

	return (
		<Container maxWidth='md' className={classes.root}>
			<div className={classes.makeSpace}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} className={classes.title}>
						{text.quote_header}
					</Grid>
					<Grid item container spacing={1} className={classes.container}>
						<Grid item xs={12} sm={6}>
							<CustomTextField
								placeholder={`${text.name} *`}
								value={input.firstName}
								onChange={handleChange('firstName')}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<CustomTextField
								placeholder={`${text.lastName} *`}
								value={input.lastName}
								onChange={handleChange('lastName')}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<CustomTextField
								placeholder={`${text.email} *`}
								value={input.email}
								onChange={handleChange('email')}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									clearable={false}
									autoOk
									fullWidth
									placeholder={text.dob}
									okLabel={false}
									margin='none'
									format='MM/dd/yyyy'
									value={input.dob}
									InputLabelProps={{ shrink: true }}
									type='new-password'
									autoComplete='off'
									classes={{ root: textFieldClasses.anotherRoot }}
									InputProps={{
										classes: textFieldClasses,
										endAdornment: (
											<InputAdornment
												position='end'
												style={{ cursor: 'pointer' }}
											>
												<EventIcon />
											</InputAdornment>
										),
									}}
									onChange={(date) => {
										const newDate =
											date == null ? null : moment(date).format('L')
										handleChange('dob')(newDate)
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item xs={12} sm={6}>
							<CustomTextField
								placeholder={`${text.cel} *`}
								value={input.cel}
								onChange={handleChange('cel')}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<CustomTextField
								placeholder={text.phone}
								value={input.phone}
								onChange={handleChange('phone')}
							/>
						</Grid>
						<Grid item xs={12}>
							<CustomTextField
								placeholder={`${text.office} *`}
								value={input.location}
								onChange={handleChange('location')}
								disabled={true}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Container className={classes.chooserContainer}>
					<Chooser enableDarkMode={true} goto={handleSubmit} loading={loading} />
				</Container>
			</div>
		</Container>
	)
}

export default Quote
