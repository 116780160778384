import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'

import clsx from 'clsx'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import TouchAppIcon from '@material-ui/icons/TouchApp'

import { useDispatch, useSelector } from 'react-redux'
import { updateOptions } from 'store/actions/options'
import { updateListValues, updateNewOption } from 'store/actions/chooser'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import gif from 'assets/gifs/loading.gif'

import { makeStyles, useTheme } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import { scrollTo } from 'assets/js/utils'
import CustomTextField from 'components/CustomTextField'

import { useSnackbar } from 'notistack'
import ChooserAutocomplete from 'components/Chooser/ChooserAutocomplete'
import {
	isBad,
	getCurrentUnit,
	getCurrentSize,
	units,
	initialNewOption,
} from 'components/Chooser/utils'
import CustomTooltip from 'components/Chooser/CustomTooltip'
import NumberTextField from 'components/Chooser/NumberTextField'
import { useText } from 'contexts/textContext'
import { units as unitValues } from 'assets/js/constants'

const max_warehouses = 3

const useStyles = makeStyles((theme) => ({
	buttonTab: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: 'transparent',
		border: `1px solid ${theme.palette.white}`,
		width: '100%',
		'& > div': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '2px 4px 4px 2px',
			borderTop: '2px solid transparent',
			borderLeft: '2px solid transparent',
			whiteSpace: 'nowrap',
			fontSize: theme.typography.body2.fontSize,
			width: '100%',
		},
		'&:hover': {
			backgroundColor: theme.palette.white,
			'& *': {
				color: theme.palette.green,
			},
			'& > div': {
				borderTop: `2px solid ${theme.palette.green}`,
				borderLeft: `2px solid ${theme.palette.green}`,
			},
		},
	},
	giffy: {
		maxHeight: '1.25rem',
		height: '100%',
		width: 'auto',
		padding: theme.spacing(0, 0.5),
		[theme.breakpoints.down('sm')]: {
			height: '1rem',
		},
	},
	buttonTab_darkMode: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: 'transparent',
		border: `1px solid ${theme.palette.white} !important`,
		width: '100%',
		'& > div': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '2px 4px 4px 2px',
			borderTop: '2px solid transparent !important',
			borderLeft: '2px solid transparent !important',
			whiteSpace: 'nowrap',
			fontSize: theme.typography.body2.fontSize,
			width: '100%',
		},
		'&:hover': {
			backgroundColor: theme.palette.black,
			'& *': {
				color: `${theme.palette.white} !important`,
			},
			'& > div': {
				borderTop: `2px solid ${theme.palette.white} !important`,
				borderLeft: `2px solid ${theme.palette.white} !important`,
			},
		},
	},
	selected_buttonTab: {
		backgroundColor: theme.palette.white,
		color: theme.palette.green,
		'& *': {
			color: theme.palette.green,
		},
		'& > div': {
			'& *': {
				color: theme.palette.green,
			},
			color: theme.palette.green,
			borderTop: `2px solid ${theme.palette.green}`,
			borderLeft: `2px solid ${theme.palette.green}`,
		},
	},
	selected_buttonTab_darkMode: {
		backgroundColor: theme.palette.black,
		color: `${theme.palette.white} !important`,
		'& *': {
			color: `${theme.palette.white} !important`,
		},
		'& > div': {
			'& *': {
				color: `${theme.palette.white} !important`,
			},
			color: `${theme.palette.white} !important`,
			borderTop: `2px solid ${theme.palette.white} !important`,
			borderLeft: `2px solid ${theme.palette.white} !important`,
		},
	},
	header: {
		fontSize: theme.typography.h2.fontSize,
		fontWeight: 900,
		lineHeight: 1,
		'& span': {
			display: 'block',
		},
		[theme.breakpoints.only('sm')]: {
			textAlign: 'center',
			'& span': {
				display: 'inline-block',
			},
		},
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
			'& span': {
				display: 'block',
			},
		},
	},
	boxPadding: {
		padding: theme.spacing(3, 0, 1.2, 0),
	},
	calculator: {
		border: `1px solid ${theme.palette.white}`,
		padding: theme.spacing(1, 1),
	},
	root: {
		height: '100% !important',
	},
	text: {
		color: theme.palette.white,
		fontSize: theme.typography.body2.fontSize - 2,
		padding: theme.spacing(1.5),
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	left: {
		backgroundColor: theme.palette.yellow,
	},
	right: {
		backgroundColor: theme.palette.red,
		fontWeight: 900,
		fontSize: theme.typography.h6.fontSize,
		cursor: 'pointer',
		'&:hover': {
			color: theme.palette.yellow,
		},
	},
	darkMode: {
		color: `${theme.palette.black} !important`,
		borderColor: `${theme.palette.black} !important`,
		'*': {
			color: `${theme.palette.black} !important`,
			borderColor: `${theme.palette.black} !important`,
		},
		'& *': {
			color: `${theme.palette.black} !important`,
			borderColor: `${theme.palette.black} !important`,
		},
		'& > *': {
			color: `${theme.palette.black} !important`,
			borderColor: `${theme.palette.black} !important`,
		},
	},
	boxContainer: {
		paddingRight: 2,
		height: 64,
	},
	blackBox: {
		border: `1px solid ${theme.palette.black}`,
	},
	whiteBox: {
		border: `1px solid ${theme.palette.white}`,
	},
	dynamicBox: {
		width: 0,
		backgroundColor: theme.palette.yellow,
		transition: '.5s',
	},
	bg1: {
		backgroundColor: theme.palette.yellow,
	},
	bg2: {
		backgroundColor: theme.palette.blue,
	},
	bg3: {
		backgroundColor: theme.palette.darkRed,
	},
	bg4: {
		backgroundColor: theme.palette.darkBlue,
	},
	bg5: {
		backgroundColor: theme.palette.darkYellow,
	},
	bg6: {
		backgroundColor: theme.palette.red,
	},
	bg7: {
		backgroundColor: theme.palette.black,
	},
	dimensions: {
		fontSize: theme.typography.caption.fontSize,
		color: 'gray',
	},
	listName: {
		marginRight: '1rem',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		fontSize: theme.typography.body2.fontSize,
	},
	moreButton: {
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: theme.typography.body2.fontSize,
	},
	h4: {
		fontSize: theme.typography.h4.fontSize,
	},
	body1: {
		fontSize: theme.typography.body1.fontSize,
	},
	body2: {
		fontSize: theme.typography.body2.fontSize,
	},
	tableListName: {
		marginRight: '1rem',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		fontSize: theme.typography.body1.fontSize,
	},
	tableDimensions: {
		fontSize: theme.typography.body2.fontSize - 3,
		lineHeight: theme.typography.body2.lineHeight - 0.5,
		color: theme.palette.white,
	},
	darkInput: {
		'& .MuiOutlinedInput-root': {
			borderColor: `${theme.palette.black} !important`,
		},
	},
	lightInput: {
		'& .MuiOutlinedInput-root': {
			borderColor: `${theme.palette.white} !important`,
		},
	},
	customCreateButton: {
		width: '100%',
		padding: theme.spacing(1, 3),
		fontSize: theme.typography.body1.fontSize,
		cursor: 'pointer',
		backgroundColor: 'rgba(0, 0, 0, 0.12)',
	},
	emptyRowContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
	},
	emptyRow: {
		fontSize: theme.typography.caption.fontSize,
		color: theme.palette.white,
		padding: theme.spacing(0, 1),
	},
	emptyRowLine: {
		height: 1,
		width: '15%',
		backgroundColor: theme.palette.white,
	},
	emptyRowLineDarkMode: {
		height: 1,
		width: '15%',
		backgroundColor: theme.palette.black,
	},
}))

const isEmpty = (listValues) =>
	listValues.length === 1 && listValues[0].length === 0

function Chooser(props) {
	const classes = useStyles()
	const theme = useTheme()
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()
	const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'))
	const text = useText()

	const [newOption, listValues, defaultValue] = useSelector((state) => [
		state.chooser.newOption,
		state.chooser.listValues,
		state.chooser.defaultValue,
	])

	const [tab, setTab] = React.useState(0)
	const [unit, setUnit] = React.useState(0)
	const [tutorial, setTutorial] = React.useState(0)

	const setListValues = React.useCallback(
		(newListValues) => {
			dispatch(updateListValues(newListValues))
		},
		[dispatch]
	)

	const setNewOption = React.useCallback(
		(newNewOption) => {
			dispatch(updateNewOption(newNewOption))
		},
		[dispatch]
	)

	const handleOption = React.useCallback(
		(e) => {
			const target = e.target

			setNewOption({ ...newOption, [target.name]: target.value })
		},
		[newOption, setNewOption]
	)

	const handleChange = React.useCallback(
		(_, selected) => {
			let newListValues = listValues.slice()
			let newVals = newListValues[unit].slice()

			if (tutorial === 1) setTutorial(2)

			newVals.push({ ...selected, count: 1 })

			if (getCurrentSize(newVals) > getCurrentUnit(newVals)) {
				const nonFullUnitIndex = newListValues.findIndex(
					(vals) =>
						getCurrentSize([...vals, { ...selected, count: 1 }]) <=
						units[units.length - 1]
				)

				if (nonFullUnitIndex !== -1) {
					let nonFullUnit = newListValues[nonFullUnitIndex].slice()

					nonFullUnit.push({ ...selected, count: 1 })
					newListValues[nonFullUnitIndex] = nonFullUnit

					setListValues(newListValues)
				} else if (newListValues.length < 3) {
					newListValues.push([{ ...selected, count: 1 }])
					setListValues(newListValues)
				} else {
					enqueueSnackbar(text.warehouses_full, {
						variant: 'warning',
						preventDuplicate: true,
					})
				}
			} else {
				newListValues[unit] = newVals
				setListValues(newListValues)
			}
		},
		[
			unit,
			listValues,
			setListValues,
			enqueueSnackbar,
			tutorial,
			setTutorial,
			text,
		]
	)

	const addOption = React.useCallback(
		(e) => {
			e.preventDefault()

			if (isBad(newOption))
				enqueueSnackbar(text.warehouse_create_error, {
					variant: 'error',
					preventDuplicate: true,
				})

			let cleanOption = Object.keys(initialNewOption).reduce(
				(obj, k) => ({
					...obj,
					[k]: newOption[k]?.trim() || initialNewOption[k],
				}),
				{}
			)

			if (isBad(cleanOption)) return

			setNewOption(initialNewOption)
			dispatch(updateOptions(cleanOption))
			handleChange(null, cleanOption)
			enqueueSnackbar(
				`${text.item_created[0]} ${cleanOption.name} ${text.item_created[1]}`,
				{ variant: 'success' }
			)
			setTab(0)
		},
		[newOption, setNewOption, enqueueSnackbar, dispatch, handleChange, text]
	)

	const toggleTutorial = React.useCallback(() => {
		let timeout = null

		if (!tutorial) setTimeout(() => setTutorial(0), 60000)

		let index = 1
		if (listValues.length > 1 || listValues[0].length > 0) index = 2

		setTutorial(tutorial === 0 ? index : 0)

		return () => {
			if (timeout) clearTimeout(timeout)
		}
	}, [tutorial, listValues])

	const removeRow = React.useCallback(
		(i, j) => {
			let newListValues = listValues.slice()
			let newVals = newListValues[i].slice()
			newVals.splice(j, 1)
			newListValues[i] = newVals
			setListValues(newListValues)
		},
		[listValues, setListValues]
	)

	const addUnit = React.useCallback(() => {
		if (tutorial === 3) setTutorial(4)

		setListValues([...listValues, []])
	}, [listValues, setListValues, tutorial])

	const handleCountAdd = React.useCallback(
		(add, i, j) => {
			let newListValues = listValues.slice()
			let newVals = newListValues[i].slice()

			if (tutorial === 2) setTutorial(3)

			const val = newVals[j].count + add
			// keep between 1 and 99 (inclusive)
			const count = val > 0 ? (val < 100 ? val : 99) : 1

			newVals[j] = { ...newVals[j], count }

			if (getCurrentSize(newVals) > getCurrentUnit(newVals)) {
				const nonFullUnitIndex = newListValues.findIndex(
					(vals) =>
						getCurrentSize([...vals, { ...newVals[j], count: 1 }]) <=
						units[units.length - 1]
				)

				if (nonFullUnitIndex !== -1) {
					let nonFullUnit = newListValues[nonFullUnitIndex].slice()
					const matchIndex = nonFullUnit.findIndex(
						(r) => r.name === newVals[j].name
					)

					if (matchIndex !== -1) {
						const vval = nonFullUnit[matchIndex].count + add
						// keep between 1 and 99 (inclusive)
						const ccount = vval > 0 ? (vval < 100 ? vval : 99) : 1
						nonFullUnit[matchIndex] = { ...newVals[j], count: ccount }

						newListValues[nonFullUnitIndex] = nonFullUnit
					} else {
						nonFullUnit.push({ ...newVals[j], count: 1 })
						newListValues[nonFullUnitIndex] = nonFullUnit
					}

					setListValues(newListValues)
				} else if (newListValues.length < max_warehouses) {
					newListValues.push([{ ...newVals[j], count: 1 }])
					setListValues(newListValues)
				} else {
					enqueueSnackbar(text.warehouses_full, {
						variant: 'warning',
						preventDuplicate: true,
					})
				}
			} else {
				newListValues[i] = newVals
				setListValues(newListValues)
			}
		},
		[listValues, setListValues, enqueueSnackbar, tutorial, text]
	)

	React.useEffect(() => {
		const width = window.innerWidth
		if (width > theme.breakpoints.values.md) scrollTo(`#divider-${unit}`, 1)
	}, [listValues, unit, theme])

	return (
		<Grid
			container
			spacing={4}
			alignItems='flex-start'
			className={clsx(classes.root, props.enableDarkMode && classes.darkMode)}
		>
			<Grid item xs={12} md={5}>
				<Grid container direction='column' spacing={0}>
					<Grid item className={classes.header}>
						{props.withTitle && (
							<>
								{text.calculator[0]}
								<span />
								{text.calculator[1]}
							</>
						)}
						<Grid
							container
							spacing={1}
							justify='flex-start'
							className={classes.boxPadding}
						>
							{listValues.map((l, i) => (
								<Grid item xs={12} key={i}>
									<div className={classes.boxContainer}>
										<div
											className={clsx(
												props.enableDarkMode
													? classes.blackBox
													: classes.whiteBox
											)}
											style={{
												position: 'relative',
												height: '100%',
												width: `${(
													50 +
													((getCurrentUnit(l) - units[0]) /
														(units[units.length - 1] - units[0])) *
														50
												).toFixed(1)}%`,
											}}
										>
											<div
												style={{
													height: '100%',
													width: '100%',
													position: 'absolute',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												>
													<div style={{ display: 'flex' }}>
														<div
															style={{
																paddingRight: 8,
																display: 'flex',
																alignItems: 'center',
																fontWeight: 'bold',
																paddingTop: 1,
															}}
															className={classes.h4}
														>
															{`${(
																(getCurrentSize(l) / getCurrentUnit(l)) *
																100
															).toFixed(0)}%`}
														</div>
														<div
															style={{
																borderLeft: '2px solid white',
																paddingLeft: 8,
																display: 'flex',
																flexDirection: 'column',
																justifyContent: 'center',
															}}
														>
															<div
																style={{ fontWeight: 'bold' }}
																className={classes.body2}
															>
																{`${text.warehouse} ${i + 1}`}
															</div>
															<div className={classes.body2}>
																{`${
																	isEmpty(listValues)
																		? unitValues[defaultValue]
																		: getCurrentUnit(l).toFixed(1)
																}m²`}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div
												className={clsx(
													classes.dynamicBox,
													classes[`bg${units.indexOf(getCurrentUnit(l)) + 1}`]
												)}
												style={{
													height: '100%',
													maxWidth: '100%',
													width: `${(
														(getCurrentSize(l) / getCurrentUnit(l)) *
														100
													).toFixed(1)}%`,
												}}
											></div>
										</div>
									</div>
								</Grid>
							))}
						</Grid>
					</Grid>
					<Grid item>
						<Grid container justify='center' alignItems='center' spacing={1}>
							<Grid item xs={6}>
								<button
									style={{ cursor: 'pointer' }}
									className={clsx(
										props.enableDarkMode
											? classes.buttonTab_darkMode
											: classes.buttonTab,
										tab === 0 &&
											(props.enableDarkMode
												? classes.selected_buttonTab_darkMode
												: classes.selected_buttonTab)
									)}
									onClick={() => setTab(0)}
								>
									<div>
										{/* <FiberManualRecordIcon style={{ fontWeight: 'normal' }} />{' '} */}
										{tab !== 0 && (
											<TouchAppIcon style={{ fontWeight: 'normal' }} />
										)}{' '}
										<div
											style={{
												fontWeight: tab === 0 ? 'bold' : 'inherit',
												textAlign: 'right',
												width: '100%',
											}}
											className={classes.body2}
										>
											{text.items_list}
										</div>
									</div>
								</button>
							</Grid>
							<Grid item xs={6}>
								<CustomTooltip
									open={tutorial === 5}
									placement='right'
									title={text.tooltip_create_item}
								>
									<button
										style={{ cursor: 'pointer' }}
										className={clsx(
											props.enableDarkMode
												? classes.buttonTab_darkMode
												: classes.buttonTab,
											tab === 1 &&
												(props.enableDarkMode
													? classes.selected_buttonTab_darkMode
													: classes.selected_buttonTab)
										)}
										onClick={() => {
											if (tutorial === 5) {
												setTutorial(6)
												setTimeout(() => {
													toggleTutorial()
												}, 10000)
											}

											setTab(1)
										}}
									>
										<div>
											{/* <DashboardIcon style={{ fontWeight: 'normal' }} />{' '} */}
											{tab !== 1 && (
												<TouchAppIcon style={{ fontWeight: 'normal' }} />
											)}{' '}
											<div
												style={{
													fontWeight: tab === 1 ? 'bold' : 'inherit',
													textAlign: 'right',
													width: '100%',
												}}
												className={classes.body2}
											>
												{text.item_create}
											</div>
										</div>
									</button>
								</CustomTooltip>
							</Grid>
						</Grid>
					</Grid>
					{tab === 0 ? (
						<>
							{listValues.map((_, i) => (
								<Grid item key={i}>
									<ChooserAutocomplete
										listValues={listValues}
										tutorial={tutorial}
										setTutorial={setTutorial}
										unit={unit}
										setUnit={setUnit}
										i={i}
										enableDarkMode={props.enableDarkMode}
									/>
								</Grid>
							))}
							<Grid item>
								<Grid container justify='space-between' alignItems='center'>
									<Grid item>
										<CustomTooltip
											placement='right'
											title={
												tutorial
													? text.tooltip_tutorial_end
													: text.tooltip_tutorial_start
											}
										>
											<IconButton
												style={{ padding: 4 }}
												onClick={() => toggleTutorial()}
											>
												{tutorial ? (
													<CancelIcon size='small' style={{ padding: 0 }} />
												) : (
													<HelpOutlineIcon
														size='small'
														style={{ padding: 0 }}
													/>
												)}
											</IconButton>
										</CustomTooltip>
									</Grid>
									<Grid item>
										<CustomTooltip
											open={tutorial === 3}
											placement='right'
											title={`${text.tooltip_add_warehouse[0]} ${max_warehouses}${text.tooltip_add_warehouse[1]}`}
										>
											<Button
												disabled={listValues.length >= max_warehouses}
												disableElevation
												variant='text'
												startIcon={<AddIcon />}
												onClick={addUnit}
											>
												<span className={classes.moreButton}>
													{text.add_warehouse}
												</span>
											</Button>
										</CustomTooltip>
									</Grid>
								</Grid>
							</Grid>
						</>
					) : (
						<Grid item container spacing={1} style={{ marginTop: 4 }}>
							<Grid item xs={12}>
								<CustomTooltip
									open={tutorial === 6}
									placement='right'
									title={text.tooltip_new_item_name}
								>
									<form onSubmit={addOption}>
										<CustomTextField
											name='name'
											placeholder={text.name}
											value={newOption.name}
											onChange={handleOption}
										/>
									</form>
								</CustomTooltip>
							</Grid>
							{[
								{
									key: 'length',
									name: text.calculator_length,
									help: text.calculator_length_title,
								},
								{
									key: 'width',
									name: text.calculator_width,
									help: text.calculator_width_title,
								},
								{
									key: 'height',
									name: text.calculator_height,
									help: text.calculator_height_title,
								},
							].map((item, alt) => (
								<Grid item xs={4} key={item.key}>
									<CustomTooltip
										open={tutorial === 6}
										placement={alt % 2 === 0 ? 'bottom' : 'top'}
										title={item.help}
									>
										<form onSubmit={addOption}>
											<CustomTextField
												name={item.key}
												value={newOption[item.key]}
												onChange={handleOption}
												placeholder={item.name}
												error={
													newOption[item.key].trim() !== '' &&
													!parseFloat(newOption[item.key])
												}
												inputProps={{ style: { textAlign: 'center' } }}
											/>
										</form>
									</CustomTooltip>
								</Grid>
							))}
							<Grid item xs={12}>
								<button
									onClick={addOption}
									className={classes.customCreateButton}
								>
									{text.create}
								</button>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Grid
				item
				xs={12}
				md={7}
				container
				direction='column'
				justify='space-between'
				style={{ alignSelf: 'flex-start' }}
			>
				<Grid item container style={{ maxHeight: 510.75, overflowY: 'auto' }}>
					<Grid item xs={12}>
						<List
							className={classes.calculator}
							style={{
								borderWidth:
									listValues.length === 0 ? 'transparent' : 'inherit',
							}}
						>
							{listValues.map((l, i) => (
								<React.Fragment key={i}>
									<Grid container justify='space-between' alignItems='center'>
										<Grid
											item
											className={classes.body2}
											style={{ paddingLeft: 7, fontWeight: 'bold' }}
										>{`${text.warehouse} ${i + 1}`}</Grid>
										<Grid
											item
											className={classes.body2}
											style={{ paddingRight: 7, fontWeight: 'bold' }}
										>
											{`${text.suggested_size}: ${
												isEmpty(listValues)
													? unitValues[defaultValue]
													: getCurrentUnit(l).toFixed(1)
											}m²`}
										</Grid>
									</Grid>
									<Divider
										style={{
											margin: '.5rem',
											backgroundColor: props.enableDarkMode
												? 'rgb(34,31,32)'
												: 'white',
										}}
									/>
									{l.length <= 0 ? (
										<ListItem>
											<ListItemText style={{ margin: 0 }}>
												<div className={classes.emptyRowContainer}>
													<div
														className={clsx(
															props.enableDarkMode
																? classes.emptyRowLineDarkMode
																: classes.emptyRowLine
														)}
													/>
													<div className={classes.emptyRow}>
														{text.empty_row}
													</div>
													<div
														className={clsx(
															props.enableDarkMode
																? classes.emptyRowLineDarkMode
																: classes.emptyRowLine
														)}
													/>
												</div>
											</ListItemText>
										</ListItem>
									) : (
										l.map((r, j) => (
											<ListItem key={`${i}-${j}`}>
												<ListItemText
													style={{
														margin: 0,
													}}
												>
													<div className={classes.tableListName}>{r.name}</div>
													<div className={classes.tableDimensions}>
														{`${r.length}" x ${r.width}" x ${r.height}" (${text.inches})`}
													</div>
												</ListItemText>
												<ListItemSecondaryAction
													style={{ display: 'flex', alignItems: 'center' }}
												>
													<CustomTooltip
														open={tutorial === 2 && i === 0 && j === 0}
														placement='left'
														title={text.tooltip_subtract_item}
													>
														<IconButton
															onClick={() => handleCountAdd(-1, i, j)}
															disableFocusRipple
															disableRipple
															disableTouchRipple
															style={{ padding: 0, margin: '0 .5rem' }}
														>
															<RemoveIcon size='small' style={{ padding: 0 }} />
														</IconButton>
													</CustomTooltip>
													<NumberTextField
														value={r.count}
														disabled={true}
														style={{
															maxWidth: '3rem',
															padding: 0,
															margin: 0,
														}}
														inputProps={{ style: { textAlign: 'center' } }}
													/>
													<CustomTooltip
														open={tutorial === 2 && i === 0 && j === 0}
														placement='bottom-end'
														title={text.tooltip_add_item}
													>
														<IconButton
															onClick={() => handleCountAdd(1, i, j)}
															disableFocusRipple
															disableRipple
															disableTouchRipple
															style={{ padding: 0, margin: '0 .5rem' }}
														>
															<AddIcon size='small' style={{ padding: 0 }} />
														</IconButton>
													</CustomTooltip>
													<IconButton
														onClick={() => removeRow(i, j)}
														disableFocusRipple
														disableRipple
														disableTouchRipple
														style={{ padding: 0, margin: '0 .5rem' }}
														title={text.item_remove_all_title}
													>
														<HighlightOffIcon
															size='small'
															style={{ padding: 0 }}
														/>
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>
										))
									)}
									<div id={`divider-${i}`}></div>
								</React.Fragment>
							))}
						</List>
					</Grid>
				</Grid>
				<Grid item>
					<Grid container direction={matches ? 'row-reverse' : 'row'}>
						<Grid
							item
							xs={12}
							sm={8}
							md={props.enableDarkMode ? 8 : 9}
							className={clsx(classes.text, classes.left)}
						>
							{text.calculator_text}
						</Grid>
						<Grid
							item
							xs={12}
							sm={4}
							md={props.enableDarkMode ? 4 : 3}
							className={clsx(classes.text, classes.right)}
							onClick={() => {
								if (!props.loading)
									props.goto(
										listValues,
										listValues.map((v) => getCurrentUnit(v))
									)
							}}
							disabled={listValues.some((l) => l.length > 0)}
						>
							{props.loading ? (
								<img src={gif} className={classes.giffy} />
							) : props.enableDarkMode ? (
								text.quote_send
							) : (
								text.quote
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

Chooser.propTypes = {
	goto: PropTypes.func.isRequired,
	enableDarkMode: PropTypes.bool,
	loading: PropTypes.bool,
	withTitle: PropTypes.bool,
}

export default Chooser
