import types from 'store/types.json'

const initialState = false

const bubbles = (state=initialState, action) => {
	switch (action.type) {
		case types.UPDATE_BUBBLES:
			return action.payload
		default:
			return state
	}
}

export default bubbles
