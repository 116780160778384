import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'

import lock from 'assets/images/lock.jpg'
import keypad from 'assets/images/keypad.jpg'
import woman from 'assets/images/woman.jpg'
import useImages from 'hooks/useImages'

import UnitSlideBar from 'components/UnitSliderBar'

import facebook from 'assets/icons/facebook_white.png'
import instagram from 'assets/icons/instagram_white.png'
import Hidden from '@material-ui/core/Hidden'

import { scrollTo } from 'assets/js/utils'

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import IconButton from '@material-ui/core/IconButton'

import useCurrentLocation from 'hooks/useCurrentLocation'
import { updateBubbles } from 'store/actions/bubbles'
import { useDispatch, useSelector } from 'react-redux'
import useTabs from 'hooks/useTabs'
import { useRoutes } from 'contexts/routesContext'
import { useText } from 'contexts/textContext'

import { COLORS } from 'assets/js/constants'

const images = [keypad, lock, woman]

const useStyles = makeStyles((theme) => ({
	container: {
		width: theme.breakpoints.values.md,
		position: 'relative',
		height: 600,
		[theme.breakpoints.down('xs')]: {
			height: '90vh',
		},
	},
	inner: {
		position: 'absolute',
		height: '100%',
		maxHeight: 600,
		zIndex: 999,
	},
	marginTop: {
		marginTop: 160,
		[theme.breakpoints.down('sm')]: {
			marginTop: 64,
		},
	},
	unitSlideBar: {
		backgroundColor: theme.palette.black,
		padding: theme.spacing(4, 6, 3, 6),
	},
	text: {
		color: theme.palette.white,
		fontSize: theme.typography.body2.fontSize - 2,
		padding: theme.spacing(1.5),
		textAlign: 'center',
		textDecoration: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	top: {
		position: 'absolute',
		zIndex: 1,
		height: 54,
		marginTop: -54,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		background: 'linear-gradient(transparent, rgba(34,31,32,.6) 80%)',
		[theme.breakpoints.down('sm')]: {
			background: 'linear-gradient(transparent, rgba(34,31,32,.6) 90%)',
		},

		'& > div': {
			marginTop: 12,
			textTransform: 'uppercase',
			fontWeight: 'bold',
			color: theme.palette.white,
			fontSize: theme.typography.body2.fontSize,
			textAlign: 'center',
			textDecoration: 'none',
		},
	},
	left: {
		backgroundColor: theme.palette.yellow,
	},
	right: {
		backgroundColor: theme.palette.red,
		fontWeight: 900,
		fontSize: theme.typography.h6.fontSize,
		cursor: 'pointer',
		'&:hover': {
			color: theme.palette.yellow,
		},
	},
	socialMedia: {
		height: 18,
		width: 'auto',
		padding: theme.spacing(1),
		userSelect: 'none',
	},
	radio: {
		fontWeight: 'bold',
		color: theme.palette.white,
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		userSelect: 'none',
	},
	innerRadio: {
		position: 'absolute',
		fontSize: 12,
		color: theme.palette.white,
	},
	sideNavigationContainer: {
		height: 0,
		marginTop: 74,
		width: '100%',
		maxWidth: theme.breakpoints.values.md,
		position: 'fixed',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.up(theme.breakpoints.values.md + 96)]: {
			maxWidth: theme.breakpoints.values.md + 96,
			marginLeft: -48,
		},
	},
	faderContainer: {
		width: '100%',
		maxWidth: theme.breakpoints.values.md,
		position: 'relative',
		height: 600,
		[theme.breakpoints.down('xs')]: {
			height: '90vh',
		},
		'& > div': {
			['-webkit-transition']: 'opacity 1s ease-in-out',
			['-moz-transition']: 'opacity 1s ease-in-out',
			['-o-transition']: 'opacity 1s ease-in-out',
			transition: 'opacity 1s ease-in-out',
		},
	},
	faderImage: {
		width: '100%',
		maxWidth: theme.breakpoints.values.md,
		height: 600,
		[theme.breakpoints.down('xs')]: {
			height: '90vh',
		},

		zIndex: 1,
		position: 'absolute',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundColor: theme.palette.black,
	},
}))

function Home({ components }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const text = useText()

	// const [reload, setReload] = React.useState()
	// const forceReload = React.useCallback(() => {
	// 	const id = setTimeout(() => {
	// 		setReload(null)
	// 	}, 500)
	// 	setReload(id)

	// 	return () => {
	// 		clearTimeout(reload)
	// 	}
	// }, [reload])

	// React.useEffect(() => {
	// 	window.addEventListener('resize', forceReload)

	// 	return () => {
	// 		window.removeEventListener('resize', forceReload)
	// 	}
	// }, [forceReload])

	const [bubbles] = useSelector((state) => [state.bubbles])

	const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'))
	const [, index] = useImages(images, 10000)
	const [, hash] = useCurrentLocation()
	const { routesMap } = useRoutes()
	const tabs = useTabs()

	React.useEffect(() => {
		scrollTo(hash, 0, 500)
	}, [hash])

	const handleBubbles = React.useCallback(
		(bubble) => dispatch(updateBubbles(bubble)),
		[dispatch]
	)

	return (
		<Container maxWidth='md' style={{ padding: 0 }}>
			<Grid container direction='column' alignItems='center'>
				<Grid item xs={12} className={classes.container}>
					<Grid
						container
						justify='center'
						alignItems='center'
						className={classes.inner}
					>
						<Hidden smDown>
							<div
								style={{
									position: 'absolute',
									width: '100%',
								}}
							>
								<div className={classes.sideNavigationContainer}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
										}}
									>
										<div>
											<IconButton
												className={classes.radio}
												onClick={() => {
													handleBubbles('#a')
													scrollTo('#a')
												}}
											>
												{'#a' === bubbles && (
													<FiberManualRecordIcon
														className={classes.innerRadio}
													/>
												)}
												<RadioButtonUncheckedIcon fontSize='default' />
											</IconButton>
										</div>
										{tabs.map(
											(tab) =>
												!tab.notDot && (
													<div key={tab.id}>
														<IconButton
															className={classes.radio}
															onClick={() => {
																handleBubbles(tab.id)
																scrollTo(tab.id)
															}}
														>
															{tab.id === bubbles && (
																<FiberManualRecordIcon
																	className={classes.innerRadio}
																/>
															)}
															<RadioButtonUncheckedIcon fontSize='default' />
														</IconButton>
													</div>
												)
										)}
									</div>

									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
										}}
									>
										<div>
											<a
												target='_blank'
												rel='noopener noreferrer'
												href='https://www.instagram.com/citystoragerd'
											>
												<img src={instagram} className={classes.socialMedia} />
											</a>
										</div>
										<div>
											<a
												target='_blank'
												rel='noopener noreferrer'
												href='https://www.facebook.com/citystoragerd'
											>
												<img src={facebook} className={classes.socialMedia} />
											</a>
										</div>
									</div>
								</div>
							</div>
						</Hidden>

						<Grid item xs={12} onMouseOver={() => handleBubbles('#a')}>
							<Grid container justify='center' alignItems='center'>
								<Grid item xs={11} sm={8} md={6}>
									<Grid container className={classes.marginTop}>
										<Grid item xs={12} className={classes.unitSlideBar}>
											<UnitSlideBar />
										</Grid>
										<Grid
											item
											xs={12}
											container
											direction={matches ? 'row-reverse' : 'row'}
										>
											<Grid
												item
												xs={12}
												sm={8}
												md={9}
												className={clsx(classes.text, classes.left)}
											>
												{text.slider_text}
											</Grid>
											<Grid
												item
												xs={12}
												sm={4}
												md={3}
												className={clsx(classes.text, classes.right)}
												component={Link}
												to={routesMap['/quote']}
											>
												{text.quote}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<div className={classes.faderContainer}>
						<div
							className={classes.faderImage}
							style={{
								backgroundImage: `url(${keypad})`,
								opacity: index === 0 ? 1 : 0,
							}}
						/>
						<div
							className={classes.faderImage}
							style={{
								backgroundImage: `url(${lock})`,
								opacity: index === 1 ? 1 : 0,
							}}
						/>
						<div
							className={classes.faderImage}
							style={{
								backgroundImage: `url(${woman})`,
								opacity: index === 2 ? 1 : 0,
							}}
						/>
					</div>

					<div
						// style={{  }}
						className={classes.top}
					>
						<div>{text.slider_title}</div>
					</div>
				</Grid>

				<Grid item xs={12}>
					{components.map((child) => (
						<div
							key={child.id}
							id={child.id.slice(1)}
							onMouseOver={() => handleBubbles(child.id)}
						>
							<child.component {...child.props} />
						</div>
					))}
				</Grid>
			</Grid>
		</Container>
	)
}

Home.propTypes = {
	components: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			component: PropTypes.any,
			props: PropTypes.shape({
				color: PropTypes.arrayOf(PropTypes.oneOf(COLORS)),
				views: PropTypes.arrayOf(
					PropTypes.shape({
						header: PropTypes.string.isRequired,
						content: PropTypes.any,
					})
				),
			}),
		})
	).isRequired,
}

export default Home
