import React from 'react'

import Home from 'pages/Home'
import Quote from 'pages/Quote'
import Suggestions from 'pages/Suggestions'
import Promotions from 'pages/Promotions'
import Terms from 'pages/Terms'
import Privacy from 'pages/Privacy'
import RentalForm from 'pages/RentalForm'
import FAQ from 'pages/FAQ'

import Slider from 'components/Slider'
import WrappedChooser from 'components/WrappedChooser'
import Contact from 'components/Contact'

export const tabs = [
	{
		id: '#b',
		name: 'Services',
	},
	{
		id: '#c',
		name: 'Calculator',
	},
	{
		id: '#d',
		name: 'About Us',
	},
	{
		id: '#e',
		name: 'Contact',
	},
	{
		path: ['rental-form', 'solicitud-servicio'],
		name: 'Rental',
	},
	{
		path: ['promotions', 'promociones'],
		name: 'Promotions',
		fancy: true,
		notDot: true,
	},
]

export const publicRoutes = [
	{
		path: ['/'],
		component: Home,
		components: [
			{
				id: '#b',
				component: Slider,
				props: {
					colors: ['blue', 'yellow', 'red'],
					views: [
						{
							header: 'What we offer',
							content: (
								<div>
									<p>
										You can store a great variety of accessory items such as
										furniture, personal objects, files, promotional material,
										office furnitures, equipment, business merchandise, among
										others.
									</p>

									<ul>
										{[
											'Automated access control and recording cameras 24/7.',
											'A variety of sizes from 3 to 80 square meters.',
											'Flexibility of quick change of spaces to other sizes.',
											'Climate and humidity are stabilized by flysheet and the first level is raised off the ground.',
											'The lock that will be used is the customer’s property.',
											'A minimum one (1) month rental.',
											'Individual spaces with total privacy.',
										].map((item, i) => (
											<li
												key={i}
												style={{ display: 'flex', alignItems: 'flex-start' }}
											>
												<div style={{ margin: '0px 4px' }}>&bull;</div>
												<div>{item}</div>
											</li>
										))}
									</ul>
								</div>
							),
						},
						{
							header: 'Advantages and facilities',
							content: (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										height: '100%',
										wrap: 'nowrap',
									}}
								>
									<ul>
										{[
											'You pay for the time and space you actually need.',
											'You reduce your storage costs.',
											'Roofed loading areas with capacity for trucks and queues.',
											'Parking spaces with ample area for turning trucks.',
											'Access after hours without prior notice.',
											'A variety of equipment to load and move goods.',
											'Wide hallways to allow easy access for large furniture.',
											'Airtight closure option which does not allow odor pollution.',
										].map((item, i) => (
											<li
												key={i}
												style={{ display: 'flex', alignItems: 'flex-start' }}
											>
												<div style={{ margin: '0px 4px' }}>&bull;</div>
												<div>{item}</div>
											</li>
										))}
									</ul>
								</div>
							),
						},
						{
							header: 'City Shop',
							content: (
								<div>
									<h6>
										Accessory store with article provisiones for packing and
										storage.
									</h6>
									<ul>
										{[
											'Cardboard boxes',
											'Adhesive tape',
											'Laminating roll',
											'Tie wrap',
											'Markers',
											'Dehumidifier',
											'Rope',
											'Scissors',
											'Pad Locks',
											'Gloves',
											'Dust Mask',
											'Cutting Blade',
										].map((item, i) => (
											<li
												key={i}
												style={{ display: 'flex', alignItems: 'flex-start' }}
											>
												<div style={{ margin: '0px 4px' }}>✓</div>
												<div>{item}</div>
											</li>
										))}
									</ul>
								</div>
							),
						},
					],
				},
			},
			{
				id: '#c',
				component: WrappedChooser,
				props: {},
			},
			{
				id: '#d',
				component: Slider,
				props: {
					colors: ['yellow', 'blue'],
					views: [
						{
							header: 'About us',
							content: (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										height: '100%',
										wrap: 'nowrap',
									}}
								>
									<p>
										We are pioneers in the Dominican Republic in the
										Self-Storage modality, with comfortable and spacious
										warehouse facilities designed to meet the needs of our
										clients.
									</p>

									<p>
										We have conceived City Storage as a company designed to
										provide storage services on a small and medium scale,
										serving as a logistics center for personal and commercial
										use.
									</p>

									<p style={{ fontWeight: 'bold' }}>
										&ldquo;Get your Storage in only minutes&rdquo;
									</p>
								</div>
							),
						},
						{
							header: 'Self storage',
							content: (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										height: '100%',
										wrap: 'nowrap',
									}}
								>
									<p>
										City Storage is a place where you can store and manage your
										goods in a safe, comfortable easily accessed area, using
										your own lock, receiving a personalized access code and
										allowing you to enter your storage whenever you want,
										without previous notice or additional charges.
									</p>

									<p>
										You can store a great variety of goods such as accessories,
										furnitures and personal items, such as files, promotional
										materials, office furniture, equipment, merchandise, among
										others.
									</p>
								</div>
							),
						},
					],
				},
			},
			{
				id: '#e',
				component: Contact,
				props: {},
			},
		],
	},
	{
		path: ['/quote', '/cotizar'],
		component: Quote,
	},
	{
		path: ['/suggestions', '/sugerencias'],
		component: Suggestions,
	},
	{
		path: ['/promotions', '/promociones'],
		component: Promotions,
	},
	{
		path: ['/privacy', '/privacidad'],
		component: Privacy,
	},
	{
		path: ['/conditions', '/condiciones'],
		component: Terms,
	},
	{
		path: ['/rental-form', '/solicitud-servicio'],
		component: RentalForm,
	},
	{
		path: ['/faq', '/preguntas-frecuentes'],
		component: FAQ,
	},
]

export const footer = [
	{
		pathKey: '/faq',
		name: 'FAQ',
	},
	{
		pathKey: '/rental-form',
		name: 'Rental Form',
	},
	{
		pathKey: '/privacy',
		name: 'Privacy policies',
	},
	{
		pathKey: '/conditions',
		name: 'Terms and Conditions',
	},
	{
		pathKey: '/quote',
		name: 'Quote',
	},
	{
		pathKey: '/suggestions',
		name: 'Suggestions',
	},
	{
		pathKey: '/promotions',
		name: 'Promotions',
	},
	{
		pathKey: '/',
		name: 'Home',
	},
]
