import validator from 'validator'

const SCROLL_PARAMS = [
	{ behavior: 'smooth', block: 'center', inline: 'center' },
	{ behavior: 'smooth', block: 'nearest', inline: 'start' },
]

export const scrollTo = (anchor, option = 0, delay = 0) => {
	if (anchor) {
		setTimeout(() => {
			const anchorRef = document.querySelector(anchor)
			if (anchorRef) {
				anchorRef.scrollIntoView(SCROLL_PARAMS[option])
			}
		}, delay)
	}
}

export const isValidEmail = (email) => {
	const cleanEmail = email.trim().toLowerCase()
	return validator.isEmail(cleanEmail)
}

export const getCleanPhone = (phone) => {
	const x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
	const cleanPhone = !x[2] ? x[1] : x[1] + x[2] + (x[3] ? x[3] : '')
	return cleanPhone
}

export const isValidPhone = (phone) => {
	const cleanPhone = getCleanPhone(phone)
	if (!cleanPhone) return false
	return cleanPhone.length === 10
}
