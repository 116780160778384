import React from 'react'
import { useHistory } from 'react-router-dom' 
import { makeStyles } from '@material-ui/core/styles'

import Chooser from 'components/Chooser/Chooser'
import Slide from 'components/Slide'

const useStyles = makeStyles((theme) => ({
	root: {
		// padding: theme.spacing(1),
		minHeight: 256,
		height: '100%',
		width: '100%',
		padding: theme.spacing(0,2),
		'& *': {
			color: theme.palette.white,
		},
		[theme.breakpoints.down('xs')]: {
			padding: 0,
		}
	},
}))

function WrappedChoooser() {
	const classes = useStyles()
	const history = useHistory()

	return (
		<Slide colors={['br_green', 'bl_green', 'br_red']} disableMaxHeight>
			<div className={classes.root}>
				<Chooser goto={() => history.push('/cotizar')} withTitle />
			</div>
		</Slide>
	)
}

export default WrappedChoooser
