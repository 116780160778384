import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import gif from 'assets/gifs/loading.gif'
import { scrollTo } from 'assets/js/utils'
import { useRoutes } from 'contexts/routesContext'
import { useText } from 'contexts/textContext'

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: '24rem',
		paddingBottom: '8rem',
		[theme.breakpoints.down('sm')]: {
			marginTop: '18rem',
		},
	},
	giffy: {
		height: '2.5rem',
		width: 'auto',
		padding: theme.spacing(0, 0.5),
	},
	header: {
		fontSize: theme.typography.h2.fontSize,
		color: theme.palette.white,
		fontWeight: 700,
		lineHeight: 0.9,
	},
	subheader: {
		fontSize: theme.typography.h6.fontSize,
		color: theme.palette.white,
		lineHeight: 0.9,
	},
	buttonHeader: {
		marginTop: theme.spacing(8),
		fontSize: theme.typography.h4.fontSize,
		color: theme.palette.white,
		fontWeight: 700,
	},
	button: {
		fontSize: theme.typography.h5.fontSize,
		color: theme.palette.white,
		backgroundColor: theme.palette.red,
		fontWeight: 700,
		padding: theme.spacing(1, 2),
		borderRadius: 0,
		cursor: 'pointer',
	},
}))

function Confirmation({ email }) {
	const classes = useStyles()
	const { routesMap } = useRoutes()
	const text = useText()

	React.useEffect(() => {
		scrollTo('#a', { delay: 0 })
	}, [])

	return (
		<Container maxWidth='md'>
			<Grid
				container
				direction='column'
				spacing={1}
				justify='center'
				alignItems='center'
				className={classes.container}
			>
				<Grid item>
					<img src={gif} className={classes.giffy} />
				</Grid>
				<Grid item className={classes.header}>
					{text.quote_thanks}
				</Grid>
				<Grid item className={classes.subheader}>
					{text.quote_confirmation_email}{' '}
					<spam
						className={classes.subheader}
						style={{ textDecorations: 'underline' }}
					>
						{email}
					</spam>
				</Grid>
				<Grid item className={classes.buttonHeader}>
					{text.quote_more_space}
				</Grid>
				<Grid item component={Link} to={routesMap['/quote']}>
					<button className={classes.button}>{text.quote_calculate}</button>
				</Grid>
			</Grid>
		</Container>
	)
}

Confirmation.propTypes = {
	email: PropTypes.string.isRequired,
}

export default Confirmation
