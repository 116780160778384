import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import TouchAppIcon from '@material-ui/icons/TouchApp'
import { scrollTo } from 'assets/js/utils'
import rental from 'assets/json/rental.json'
import clsx from 'clsx'
import { useLanguage } from 'contexts/languageContext'
import { useText } from 'contexts/textContext'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 144 + 64,
	},
	container: {
		backgroundColor: theme.palette.white,
		padding: theme.spacing(2, 2, 4, 2),
	},
	send: {
		color: theme.palette.black,
		fontWeight: 'bold',
		justifySelf: 'flex-end',
		fontSize: theme.typography.body1.fontSize,
		cursor: 'pointer',
	},
	headerContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	header: {
		fontSize: theme.typography.h4.fontSize,
		fontWeight: 900,
	},
	headerGray: {
		color: theme.palette.darkGray,
		fontWeight: 'normal',
	},
	line: {
		height: 1,
		width: '90%',
		backgroundColor: theme.palette.black,
	},
	tabs: {
		'& .MuiTabs-indicator': {
			backgroundColor: 'transparent !important',
		},
	},
	tab: {
		padding: 0,
		textTransform: 'none',
		opacity: 1,
		'& .MuiTouchRipple-root': {
			display: 'none',
		},
		'& .MuiTab-wrapper': {
			marginTop: -2,
			border: `2px solid rgb(77,77,77)`,
			borderTop: 0,
			color: 'rgb(77,77,77)',
			zIndex: 1,
			backgroundColor: theme.palette.gray,
			fontSize: theme.typography.h4.fontSize,
			fontWeight: 900,
		},
	},
	tabSelected: {
		padding: 0,
		textTransform: 'none',
		opacity: 1,
		'& .MuiTouchRipple-root': {
			display: 'none',
		},
		'& .MuiTab-wrapper': {
			marginTop: -2,
			border: `2px solid transparent`,
			borderTop: 0,
			color: theme.palette.red,
			zIndex: 1,
			backgroundColor: 'transparent',
			fontSize: theme.typography.h4.fontSize,
			fontWeight: 900,
		},
	},
	tabBorderRight: {
		'& .MuiTab-wrapper': {
			borderRight: 0,
			paddingRight: 2,
		},
	},
	tabBorderLeft: {
		'& .MuiTab-wrapper': {
			borderLeft: 0,
			paddingLeft: 2,
		},
	},

	checkbox: {
		color: `${theme.palette.black} !important`,
	},
	body1: {
		fontSize: theme.typography.body1.fontSize,
		color: theme.palette.black,
	},
	documents: {
		fontSize: theme.typography.body1.fontSize,
		color: theme.palette.darkGray,

		'& strong': {
			fontSize: theme.typography.body1.fontSize,
			color: theme.palette.darkGray,
			fontWeight: 'bold',
		},
	},
	spacingTop: {
		marginTop: theme.spacing(4),
	},
	cSpacing: {
		padding: theme.spacing(0, 0.5),
	},
}))

const useStyleTextField = makeStyles((theme) => ({
	root: {
		color: `${theme.palette.darkGray} !important`,
		borderRadius: 0,
		backgroundColor: 'transparent',
		'& .MuiFormLabel-root': {
			'& .Mui-focused': {
				color: `${theme.palette.darkGray} !important`,
			},
			'& .Mui-focused fieldset': {
				color: `${theme.palette.darkGray} !important`,
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: theme.spacing(1),
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: `2px solid ${theme.palette.darkGray} !important`,
			},
			'&:hover fieldset': {
				border: 0,
			},
			'& .Mui-focused': {
				border: `2px solid ${theme.palette.darkGray} !important`,
				backgroundColor: 'transparent',
			},
			'& .Mui-focused fieldset': {
				backgroundColor: 'transparent',
				border: 0,
			},
		},
	},
}))

function CustomTextField(props) {
	const classes = useStyleTextField()

	return (
		<TextField
			fullWidth
			margin='dense'
			variant='outlined'
			classes={{ root: classes.root }}
			InputProps={{ classes }}
			// eslint-disable-next-line react/prop-types
			InputLabelProps={{ ...props.InputLabelProps, classes }}
			{...props}
		/>
	)
}

const useStyleIcon = makeStyles((theme) => ({
	root: {
		color: theme.palette.darkGray,
		padding: theme.spacing(1),
	},
}))

const TouchIcon = () => {
	const classes = useStyleIcon()

	return <TouchAppIcon className={classes.root} />
}

function CustomSelector({ label, value, onChange, options }) {
	const classes = useStyleTextField()
	const text = useText()

	return (
		<FormControl
			fullWidth
			margin='dense'
			variant='outlined'
			classes={{ root: classes.root }}
		>
			<InputLabel className={classes.root}>{label}</InputLabel>
			<Select
				value={value}
				onChange={onChange}
				className={classes.root}
				label={label}
				native
				IconComponent={TouchIcon}
			>
				{!value && (
					<option disabled selected>
						{text.select_empty_option}
					</option>
				)}
				{options.map((option) => (
					<option key={option} value={option}>
						{option}
					</option>
				))}
			</Select>
		</FormControl>
	)
}

CustomSelector.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const getInputDefault = (o) =>
	Object.keys(o).reduce((obj, k) => {
		const newObj = o[k].reduce(
			(obj2, item) => ({
				...obj2,
				[item.key]: item.type === 'text' ? '' : null,
			}),
			{}
		)
		return { ...obj, ...newObj }
	}, {})

function RentalForm() {
	const classes = useStyles()
	const text = useText()
	const { language } = useLanguage()

	const [input, setInput] = React.useState(getInputDefault(rental[language]))
	const [otherInput, setOtherInput] = React.useState({
		signature: '',
		date: null,
	})

	React.useEffect(() => {
		scrollTo('#a', 0, 500)
	}, [])

	const handleInput = React.useCallback(
		(key) => (e) => {
			let newInput = { ...input }
			newInput = { ...newInput, [key]: e.target.value }
			setInput(newInput)
		},
		[input]
	)

	const handleOtherInput = React.useCallback(
		(key) => (e) => {
			let newOtherInput = { ...otherInput }
			newOtherInput = { ...newOtherInput, [key]: e.target.value }
			setOtherInput(newOtherInput)
		},
		[otherInput]
	)

	return (
		<Container maxWidth='md' className={classes.root}>
			<div className={classes.container}>
				<Grid container spacing={1}>
					{Object.keys(rental[language]).map((k) => {
						const styledLabel = k.replace(
							/{{(.+)}}/,
							`<span class="${clsx(
								classes.header,
								classes.headerGray
							)}">$1</h1>`
						)

						return (
							<Grid key={k} item xs={12}>
								<div className={clsx(classes.header, classes.cSpacing)}>
									{parse(styledLabel)}
								</div>

								<Grid container>
									{rental[language][k].map((r) => {
										switch (r.type) {
											case 'text':
												return (
													<Grid
														key={r.key}
														item
														{...r.size}
														className={classes.cSpacing}
													>
														<CustomTextField
															label={`${r.text}${r.required ? ' *' : ''}`}
															value={input[r.key]}
															onChange={handleInput(r.key)}
														/>
													</Grid>
												)
											case 'radio':
											case 'select':
												return (
													<Grid
														key={r.key}
														item
														{...r.size}
														className={classes.cSpacing}
													>
														<CustomSelector
															label={r.text}
															value={input[r.key]}
															onChange={handleInput(r.key)}
															options={r.options}
														/>
													</Grid>
												)
											default:
												return null
										}
									})}
								</Grid>
							</Grid>
						)
					})}

					<Grid
						item
						xs={12}
						container
						className={classes.spacingTop}
						spacing={2}
					>
						<Grid item xs={12}>
							<div className={clsx(classes.cSpacing, classes.documents)}>
								{language === 'es' ? (
									<div className={classes.documents}>
										Con el envío de esta solicitud de servicio, autorizo a{' '}
										<strong>City Storage Solution, SRL</strong> a verificar la
										información antes suministrada y a investigar los niveles
										crediticios, con fines de aprobación de la misma.
									</div>
								) : (
									<div className={classes.documents}>
										By sending this service request, I authorize{' '}
										<strong>City Storage Solution, SRL</strong> to verify the
										information provided above and to investigate the credit
										levels, for approval purposes.
									</div>
								)}
							</div>
						</Grid>

						<Grid item xs={12}>
							{language === 'es' ? (
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<CustomTextField
											label='Firma del Cliente o Representante Legal'
											value={otherInput['signature']}
											onChange={handleOtherInput('signature')}
										/>
									</Grid>
									<Grid item xs={6}>
										<CustomTextField
											InputLabelProps={{ shrink: true }}
											type='date'
											label='Fecha'
											value={otherInput['date']}
											onChange={handleOtherInput('date')}
										/>
									</Grid>
								</Grid>
							) : (
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<CustomTextField
											label='Signature of the Client or Legal Representative'
											value={otherInput['signature']}
											onChange={handleOtherInput('signature')}
										/>
									</Grid>
									<Grid item xs={6}>
										<CustomTextField
											InputLabelProps={{ shrink: true }}
											type='date'
											label='Date'
											value={otherInput['date']}
											onChange={handleOtherInput('date')}
										/>
									</Grid>
								</Grid>
							)}
						</Grid>

						<Grid item xs={12}>
							<div className={clsx(classes.cSpacing, classes.documents)}>
								{language === 'es' ? (
									<div
										style={{ display: 'flex', justifyContent: 'space-between' }}
									>
										<div className={classes.documents}>
											Documentos requeridos:
											<br />
											- Copia de Cédula o Pasaporte (si es extranjero).
											<br />- Copia de una factura de luz o teléfono.
										</div>
										<div className={classes.documents}>
											Para empresa, incluir:
											<br />
											- Copia del Registro Mercantil.
											<br />- Copia de la cedula del representate legal.
										</div>
									</div>
								) : (
									<div
										style={{ display: 'flex', justifyContent: 'space-between' }}
									>
										<div className={classes.documents}>
											Documents required:
											<br />
											- Copy of ID or passport (ID foreigner).
											<br />- Copy of a utility bill.
										</div>
										<div className={classes.documents}>
											For business, include:
											<br />
											- Copy of the Commercial Registry.
											<br />- Copy of the identity card of the legal
											representative.
										</div>
									</div>
								)}
							</div>
						</Grid>

						<Grid item container justify='flex-end' alignItems='center'>
							<Grid item>
								<Grid
									container
									justify='flex-end'
									alignItems='center'
									className={classes.send}
								>
									{text.send} <NavigateNextIcon />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</Container>
	)
}

export default RentalForm
