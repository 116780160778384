import React from 'react'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'

import { makeStyles } from '@material-ui/core/styles'

import { scrollTo } from 'assets/js/utils'
import { useLanguage } from 'contexts/languageContext'

const useStyles = makeStyles((theme) => ({
	root: {
		'& h4': {
			fontSize: theme.typography.h4.fontSize,
		},
	},
	container: {
		backgroundColor: theme.palette.white,
		marginTop: '16rem',
		padding: theme.spacing(12, 4, 12, 4),
	},
	divider: {
		width: '100%',
		margin: theme.spacing(1, 0),
	},
	content: {
		fontSize: theme.typography.body1.fontSize,
		'& *': {
			fontSize: theme.typography.body1.fontSize,
		},
		'& > ul': {
			listStyleType: 'decimal',
			margin: theme.spacing(0, 3),
			'& > li': {
				margin: theme.spacing(1.5, 0),
			},
		},
		'& p': {
			margin: theme.spacing(1.5, 0),
		},
		'& b': {
			fontWeight: 'bold',
		},
		'& a': {
			color: 'black',
		},
	},
}))

const CityStotargeLink = () => (
	<a
		target='_blank'
		rel='noopener noreferrer'
		href='https://www.citystorage.com.do'
	>
		citystorage.com.do
	</a>
)

function Privacy() {
	const classes = useStyles()
	const { language } = useLanguage()

	React.useEffect(() => {
		scrollTo('#a', 0, 500)
	}, [])

	if (language === 'es')
		return (
			<Container maxWidth='md' className={classes.root}>
				<div className={classes.container}>
					<h4>Política de Privacidad de City Storage Solution SRL</h4>
					<Divider className={classes.divider} />

					<div className={classes.content}>
						<p>
							Bienvenido a <CityStotargeLink />.
						</p>

						<p>
							Entendemos que la privacidad en línea es importante para los
							usuarios de <CityStotargeLink />, especialmente cuando realizan
							negocios. Esta declaración rige nuestras políticas de privacidad
							con respecto a aquellos usuarios de <CityStotargeLink /> que
							visitan sin realizar transacciones comerciales y visitantes que se
							registran para realizar transacciones comerciales y hacer uso de
							los diversos servicios ofrecidos por <CityStotargeLink />.
						</p>
						<p>
							&quot;Información de identificación personal&quot; se refiere a
							cualquier información que identifique o pueda ser utilizada para
							identificar, contactar o localizar a la persona a la que pertenece
							dicha información, incluyendo, pero no limitado a, nombre,
							dirección, número de teléfono, número de fax, dirección de correo
							electrónico, perfiles financieros, número de cédula de identidad
							electoral, número de pasaporte, número de RNC e información de
							tarjeta de crédito.
						</p>

						<p>
							Esta página web es propiedad de City Storage Solution, SRL., y
							tiene carácter meramente informativo acerca de los servicios de
							almacenaje que presta nuestra empresa. El hecho de acceder a esta
							página implica el conocimiento y aceptación de los siguientes
							términos y condiciones de uso.
						</p>
						<p>
							El nombre del dominio <CityStotargeLink /> está registrado a favor
							de City Storage Solution, SRL., con RNC 1-30-89204-2 y domicilio
							en{' '}
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='https://g.page/CityStorageRD?share'
							>
								Autopista Duarte km. 10 ½, Santo Domingo, República Dominicana
							</a>
							, teléfono de contacto{' '}
							<a href='tel:+18095644284'>(809) 564-4284</a> y dirección de
							correo electrónico{' '}
							<a href='mailto:info@citystorage.com.do'>
								info@citystorage.com.do
							</a>
							.
						</p>
						<ul>
							<li>
								<p>
									<b>Propiedad Intelectual de la Web</b>
								</p>
								<p>
									Todos los derechos de propiedad intelectual del contenido de
									esta página web y su diseño gráfico, son propiedad exclusiva
									de City Storage Solution, SRL, y es a quien le corresponde el
									ejercicio exclusivo de los derechos de explotación de los
									mismos. Así, queda prohibida su reproducción, distribución,
									comunicación pública y modificación, total o parcial, sin
									previa autorización expresa de City Storage Solution, SRL. De
									igual forma, todos los signos distintivos, marcas, nombres
									comerciales en esta página web están protegidos por ley.
								</p>
								<p>
									City Storage Solution, SRL. no se responsabiliza del mal uso
									que se realice de los contenidos de su página web, siendo
									responsabilidad exclusiva de la persona que accede a ellos o
									los utiliza.
								</p>
							</li>
							<li>
								<p>
									<b>Intercambio o Difusión de Información</b>
								</p>
								<p>
									City Storage Solution, SRL declina toda responsabilidad
									derivada del intercambio de información entre usuarios a
									través de su página web. Especialmente no nos hacemos
									responsables del uso que los menores puedan hacer de ella en
									caso de que los contenidos a que tengan acceso puedan herir su
									sensibilidad.
								</p>
							</li>
							<li>
								<p>
									<b>Actualización y Modificación de la Ppágina Web</b>
								</p>
								<p>
									City Storage Solution, SRL se reserva el derecho de
									actualizar, modificar o eliminar la información contenida en
									su página web, y su configuración o presentación, en cualquier
									momento, sin previo aviso y sin asumir ningún tipo de
									responsabilidad por hacerlo.
								</p>
							</li>
							<li>
								<p>
									<b>Indicaciones Sobre Aspectos Técnicos</b>
								</p>
								<p>
									City Storage Solution, SRL no asume responsabilidad alguna
									derivada de problemas técnicos o fallos en los equipos
									informáticos, que se produzcan durante la conexión a Internet,
									o que puedan ser originados por terceras personas a través de
									intromisiones ilegítimas fuera de su control.
								</p>
							</li>
							<li>
								<p>
									<b>Tratamiento de Datos del Usuario</b>
								</p>
								<p>
									Cuando el usuario facilita sus datos de carácter personal y/o
									de curriculums utilizando nuestros formularios o e-mails de
									contacto, está autorizando expresamente a City Storage
									Solution, SRL al tratamiento automatizado de sus datos
									personales para poder atender sus peticiones de servicios y/o
									gestionar su participación en algunos de nuestros procesos de
									selección. City Storage Solution, SRL, incluirá los datos
									facilitados por el usuario en un fichero de su titularidad que
									contará con todas las medidas de seguridad necesarias. City
									Storage Solution, SRL no venderá, cederá ni distribuirá a
									terceros los datos recabados sin previo consentimiento expreso
									del usuario, excepto en el caso que la comunicación sea
									necesaria para prestarle el servicio que el usuario nos haya
									solicitado, salvo que sea requerido por un juez con una orden
									judicial.
								</p>
							</li>
							<li>
								<p>
									<b>Cookies o Fichero de Datos</b>
								</p>
								<p>
									Una cookie se refiere a un archivo que es enviado con la
									finalidad de solicitar permiso para almacenarse en su
									ordenador, al aceptar dicho archivo se crea y la cookie sirve
									entonces para tener información respecto al tráfico de las
									aplicaciones, y también facilita las futuras visitas a una
									aplicación que sea de uso recurrente. Otra función que tienen
									las cookies es que con ellas las Páginas Web pueda reconocerte
									individualmente y por tanto brindarle un servicio
									personalizado y mantener las configuraciones que haya
									realizado.
								</p>
							</li>
						</ul>
						<p>
							<b>
								City Storage Solution, SRL, se reserva el derecho de cambiar los
								términos de la presente Política de Privacidad en cualquier
								momento.
							</b>
						</p>
						<p>
							<b>
								Se prohíbe expresamente la duplicación o publicación no
								autorizada de cualquier material de esta página web.
							</b>
						</p>
					</div>
				</div>
			</Container>
		)

	return (
		<Container maxWidth='md' className={classes.root}>
			<div className={classes.container}>
				<h4>Privacy Policies of City Storage Solution SRL</h4>
				<Divider className={classes.divider} />

				<div className={classes.content}>
					<p>
						Welcome to{' '}
						<a
							target='_blank'
							rel='noopener noreferrer'
							href='https://www.citystorage.com.do'
						>
							citystorage.com.do
						</a>
						.
					</p>

					<p>
						We understand that privacy is important for <CityStotargeLink />
						users, especially for those who interact on our website for
						commercial purposes. This statement regulates our privacy policies
						with respect to other users on our website, that visit it without
						commercial transactions and for those who register for commercial
						transactions and other various services that <CityStotargeLink />
						offers.
					</p>
					<p>
						&quot;Personal Data&quot; refers to any information that identifies
						you or can be used to identify, contact or locate the person the
						data pertains to, including but not limited to: name, address,
						telephone number, fax number, e-mail address, financial profile,
						identity card number, passport number, National Taxpayer Registry
						and Credit card information.
					</p>

					<p>
						This web page belongs to City Storage Solution SRL, and is merely
						informative in regards to the space rental services provided by our
						company. By accessing this page, it implies the knowledge and
						acceptance of the following terms and conditions of use.
					</p>
					<p>
						The Domain <CityStotargeLink /> is registered in favor of City
						Storage Solution SRL, with RNC 1-30-89204-2 and located at{' '}
						<a
							target='_blank'
							rel='noopener noreferrer'
							href='https://g.page/CityStorageRD?share'
						>
							Autopista Duarte km. 10 1⁄2, Santo Domingo, República Dominicana
						</a>
						, contact number <a href='tel:+18095644284'>(809) 564-4284</a> y
						e-mail address{' '}
						<a href='mailto:info@citystorage.com.do'>info@citystorage.com.do</a>
						.
					</p>
					<ul>
						<li>
							<p>
								<b>Property Claims</b>
							</p>
							<p>
								All rights pertaining intellectual property claims of the
								contents of this website and its graphic design belong
								exclusively to City Storage Solution, SRL and has the exclusive
								rights to exploit them. Thus, its reproduction, distribution,
								public communication and total or partial modification is
								prohibited without previous authorization of City Storage
								Solution, SRL. In addition, all distinctive signs, brands and
								trade names on this website are protected by law.
							</p>
							<p>
								City Storage Solution, SRL. Is not responsible for the misuse of
								the contents of its website , being of sole responsibility of
								the person who accesses or uses it.
							</p>
						</li>
						<li>
							<p>
								<b>The Exchange or Diffusion of Information</b>
							</p>
							<p>
								City Storage Solution, SRL declines all responsibility derived
								from the exchange of information among users through its web
								page. We are specially not responsible for the use that minors
								may make of it, in the case of the contents to which they have
								access can hurt their sensitivity.
							</p>
						</li>
						<li>
							<p>
								<b>Update and Modification of the Website</b>
							</p>
							<p>
								City Storage Solution SRL, reserves the right to update, modify
								or eliminate the information that is included in its website, in
								its configuration or presentation, at any moment, without prior
								notice or assuming any kind of responsibility for doing so.
							</p>
						</li>
						<li>
							<p>
								<b>Indications on Technical Aspects</b>
							</p>
							<p>
								City Storage Solution, SRL. Will not assume any responsibility
								resulting from technical problems or computer failures, that
								occurs during the connection to the internet, or that may be
								caused by a third party through illegitimate interference beyond
								their control.
							</p>
						</li>
						<li>
							<p>
								<b>Use of Personal Data</b>
							</p>
							<p>
								When the user provides personal information for personal and/or
								curriculum data using our forms or via e-mails, you are
								expressly authorizing City Storage Solution, SRL. to
								automatically process your personal data in order to meet your
								requests for services and/or manage your participation in one of
								our selection processes. City Storage Solution, SRL will include
								this data in a file of its ownership that will have all the
								necessary security measures. City Storage Solution, SRL will not
								sell, assign or distribute this information without receiving
								the users consent , except if it is necessary to carry out the
								service requested by the user unless requested by a court of
								law.
							</p>
						</li>
						<li>
							<p>
								<b>Cookies or Data File</b>
							</p>
							<p>
								A cookie is a file that is sent requesting permission to be
								stored on your computer, by accepting said file it is created
								and the cookie then serves to retrieve information regarding the
								traffic of applications and also enables future visits to an
								application that is of recurring use. Another use for a cookie
								is that they enable the web pages to recognize you, thus
								allowing you to offer a personalized service and store
								configurations you have previously made.
							</p>
						</li>
					</ul>
					<p>
						<b>
							City Storage Solution, SRL. reserves the right to change the terms
							of this Privacy Policy at any moment.
						</b>
					</p>
					<p>
						<b>
							It is forbidden by law to duplicate or publish any unauthorized
							information from this web page.
						</b>
					</p>
				</div>
			</div>
		</Container>
	)
}

export default Privacy
