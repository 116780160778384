import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { useText } from 'contexts/textContext'

import { facebookSignIn, googleSignIn } from 'auth/auth'

const useStyles = makeStyles((theme) => ({
	socialButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		backgroundColor: 'transparent',
		padding: theme.spacing(1),
		color: theme.palette.black,
		border: `1px solid ${theme.palette.black}`,
		cursor: 'pointer',
	},
}))

function ProviderButton({ provider }) {
	const classes = useStyles()
	const text = useText()

	const providerProps = useMemo(() => {
		switch (provider) {
			case 'Google':
				return { icon: faGoogle, login: googleSignIn }
			case 'Facebook':
				return { icon: faFacebook, login: facebookSignIn }
			default:
				return { icon: null, login: () => {} }
		}
	}, [provider])

	return (
		<button className={classes.socialButton} onClick={providerProps.login}>
			<FontAwesomeIcon icon={providerProps.icon} />
			&nbsp;&nbsp;{`${text.start_provider} ${provider}`}
		</button>
	)
}

ProviderButton.propTypes = {
	provider: PropTypes.oneOf(['Google', 'Facebook']).isRequired,
}

export default ProviderButton
