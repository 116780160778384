import { tabs as esTabs, publicRoutes as esPublicRoutes, footer as esFooter } from 'routes/spanish'
import { tabs as enTabs, publicRoutes as enPublicRoutes, footer as enFooter } from 'routes/english'


export const tabs = {
	es: esTabs,
	en: enTabs,
}

export const publicRoutes = {
	es: esPublicRoutes,
	en: enPublicRoutes,
}

export const footer = {
	es: esFooter,
	en: enFooter,
}