import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'

import IconButton from '@material-ui/core/IconButton'
import { COLORS } from 'assets/js/constants'

import Slide from 'components/Slide'

const useStyles = makeStyles((theme) => ({
	container: {
		height: '100%',
	},
	flexContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	flexContainerItem: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	header: {
		fontSize: theme.typography.h2.fontSize,
		fontWeight: 900,
		textAlign: 'center',
		lineHeight: 1,
		padding: theme.spacing(1),
	},
	content: {
		'& *': {
			color: theme.palette.white,
			fontSize: theme.typography.body1.fontSize,
		},
		height: 333,
		overflow: 'auto',
		'& p': {
			marginBottom: theme.spacing(2),
		},
		[theme.breakpoints.down('sm')]: {
			height: '100%',
		},
	},
	navArrowContainer: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
			width: '12%',
			paddingRight: '1rem'
		},
		[theme.breakpoints.up('md')]: {
			width: '8%',
		},
	},
	navArrow: {
		fontSize: theme.typography.h2.fontSize,
		fontWeight: 900,
	},
}))

const getColors = (length, colors) => {
	let borderColors = []

	for (let i = 0; i < length; i++) {
		let color1 = colors[0]
		let color2 = colors[i]

		if (i > 0) color1 = colors[i - 1]
		if (i < length - 1) color2 = colors[i + 1]

		borderColors.push([`bg_${colors[i]}`, `bl_${color1}`, `br_${color2}`])
	}

	return borderColors
}
function Slider({ colors, views }) {
	const classes = useStyles()

	const [viewIndex, setViewIndex] = React.useState(0)
	const [viewColors] = React.useState(
		getColors(views.length, colors)
	)

	const handleViewIndexChange = (index) => setViewIndex(index)
	const handleClick = React.useCallback(
		(direction) => () => {
			let index = viewIndex + direction
			if (index < 0) index = 0
			else if (index >= views.length) index = views.length - 1
			setViewIndex(index)
		},
		[viewIndex, views]
	)

	return (
		<SwipeableViews
			index={viewIndex}
			onChangeIndex={handleViewIndexChange}
			animateHeight
		>
			{views.map((view, i) => (
				<Slide key={view.header} colors={viewColors[i]}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<div className={classes.navArrowContainer}>
							{i > 0 && <IconButton onClick={handleClick(-1)}>
								<NavigateBeforeIcon className={classes.navArrow} />
							</IconButton>}
						</div>

						<Grid
							container
							justify='space-between'
							direction={i % 2 ? 'row' : 'row-reverse'}
						>
							<Grid
								item
								xs={12}
								md={6}
								container
								direction='column'
								justify='center'
								alignItems='center'
							>
								<Grid item className={classes.header}>
									{view.header}
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								container
								justify='center'
								alignItems='center'
							>
								<Grid item xs={12} className={classes.content}>
									{view.content}
								</Grid>
							</Grid>
						</Grid>

						<div className={classes.navArrowContainer}>
							{i < views.length - 1 && <IconButton onClick={handleClick(1)}>
								<NavigateNextIcon className={classes.navArrow} />
							</IconButton>}
						</div>
					</div>
				</Slide>
			))}
		</SwipeableViews>
	)
}

Slider.propTypes = {
	colors: PropTypes.arrayOf(PropTypes.oneOf(COLORS).isRequired).isRequired,
	views: PropTypes.arrayOf(
		PropTypes.shape({
			header: PropTypes.string.isRequired,
			content: PropTypes.any.isRequired,
		})
	).isRequired,
}

export default Slider
