import types from '../types.json'

export const addDefaultValue = defaultValue => dispatch => {
	return dispatch({
		type: types.ADD_DEFAULT_VALUE,
		payload: defaultValue,
	})
}

export const updateNewOption = (option) => (dispatch) => {
	const data = option

	if (data)
		return dispatch({ type: types.NEW_OPTION_UPDATE_SUCCESS, payload: data })
	return dispatch({
		type: types.NEW_OPTION_UPDATE_FAILURE,
		error: 'Invalid data of null.'
	})
}

export const updateListValues = (listValues) => (dispatch) => {
	const data = listValues

	if (data)
		return dispatch({ type: types.LIST_VALUES_UPDATE_SUCCESS, payload: data })
	return dispatch({
		type: types.LIST_VALUES_UPDATE_FAILURE,
		error: 'Invalid data of null.'
	})
}

export const deleteListValues = index => (dispatch) => {
	const data = index

	if (data != null)
		return dispatch({ type: types.LIST_VALUES_DELETE_SUCCESS, payload: data })
	return dispatch({
		type: types.LIST_VALUES_DELETE_FAILURE,
		error: 'Invalid data of null.'
	})
}