import { useEffect, useState } from 'react'
import { useLanguage } from 'contexts/languageContext'
import { tabs as _tabs } from 'routes/publicRoutes'


function useTabs() {
	const { language } = useLanguage()
	const [tabs, setTabs] = useState([])

	useEffect(() => {
		setTabs(_tabs[language])
	}, [language])

	return tabs
}

export default useTabs