import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'assets/js/constants'

const bg_colors = COLORS.reduce((arr, c) => [...arr, `bg_${c}`, `bl_${c}`, `br_${c}`], [])

const useStyles = makeStyles((theme) => ({
	slide: {
		backgroundColor: theme.palette.green,
		borderLeft: '16px solid transparent',
		borderRight: '16px solid transparent',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& *': {
			color: theme.palette.white,
		},
		[theme.breakpoints.between(theme.breakpoints.values.md, theme.breakpoints.values.md + 96)]: {
			borderLeft: '34px solid transparent',
			borderRight: '34px solid transparent',
		}
	},
	slideHeight: {
		height: 350,
		[theme.breakpoints.down('sm')]: {
			height: 'inherit',
		}
	},
	padding: {
		padding: theme.spacing(3, 1),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
		},
	},
	bg_blue: {
		backgroundColor: theme.palette.blue,
		'& *': {
			color: theme.palette.yellow,
		},
	},
	bg_yellow: {
		backgroundColor: theme.palette.yellow,
		'& *': {
			color: theme.palette.green,
		},
	},
	bg_green: {
		backgroundColor: theme.palette.green,
		'& *': {
			color: theme.palette.white,
		},
	},
	bg_red: {
		backgroundColor: theme.palette.red,
		'& *': {
			color: theme.palette.white,
		},
	},
	bl_blue: {
		borderLeftColor: theme.palette.blue,
	},
	bl_yellow: {
		borderLeftColor: theme.palette.yellow,
	},
	bl_green: {
		borderLeftColor: theme.palette.green,
	},
	bl_red: {
		borderLeftColor: theme.palette.red,
	},
	br_blue: {
		borderRightColor: theme.palette.blue,
	},
	br_yellow: {
		borderRightColor: theme.palette.yellow,
	},
	br_green: {
		borderRightColor: theme.palette.green,
	},
	br_red: {
		borderRightColor: theme.palette.red,
	},
}))

function Slide(props) {
	const classes = useStyles()

	return (
		<div
			className={clsx(
				classes.slide,
				!props.disableMaxHeight && classes.slideHeight,
				!props.disablePadding && classes.padding,
				...props.colors.map((c) => classes[c])
			)}
		>
			{props.children}
		</div>
	)
}

Slide.propTypes = {
	children: PropTypes.any.isRequired,
	colors: PropTypes.arrayOf(PropTypes.oneOf(bg_colors).isRequired).isRequired,
	disablePadding: PropTypes.bool,
	disableMaxHeight: PropTypes.bool,
}

export default Slide
