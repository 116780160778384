import { useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLanguage } from 'contexts/languageContext'

export const parseOptions = (options) => 
	Object.keys(options).reduce((array, k) => {
		let ret = [{ name: k }, ...options[k]]
		return [...array, ...ret]
	}, [])

export const filterOptions = (options, language) => {
	const customOptions = options.customOptions
	const languageOptions = options.options[language]

	let ret = customOptions.map(o => ({ ...o, parent: 'custom' }))

	for (let k of Object.keys(languageOptions)) {
		const subOptions = languageOptions[k].map(
			(item) => ({ ...item, parent: k })
		)

		ret = ret.concat(subOptions)
	}

	return ret
}

function useChooserOptions() {
	const options = useSelector((state) => state.options)
	const { language } = useLanguage()

	const [filteringOptions, setFilteringOptions] = useState(filterOptions(options, language))

	const parsedOptions = useMemo(
		() => parseOptions(options.options[language]),
		[language, options]
	)
	
	useEffect(() => {
		setFilteringOptions(filterOptions(options, language))
	}, [options, language])

	return {
		customOptions: options.customOptions,
		options: options.options[language],
		parsedOptions,
		filteringOptions,
	}
}

export default useChooserOptions
