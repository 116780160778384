import types from 'store/types.json'

const initialState = {
	newOption: { name: '', length: '', width: '', height: '' },
	listValues: [[]],
	defaultValue: 0,
}

const chooser = (state=initialState, action) => {
	const listValues = state.listValues.slice()

	switch (action.type) {
		case types.ADD_DEFAULT_VALUE:
			return {
				...state,
				defaultValue: action.payload,
			}
		case types.NEW_OPTION_UPDATE_SUCCESS:
			return {
				...state,
				newOption: action.payload
			}
		case types.NEW_OPTION_UPDATE_FAILURE:
			return state
		case types.LIST_VALUES_UPDATE_SUCCESS:
			return {
				...state,
				listValues: action.payload
			}
		case types.LIST_VALUES_UPDATE_FAILURE:
			return state
		case types.LIST_VALUES_DELETE_SUCCESS:
			listValues.splice(action.payload, 1)

			return {
				...state,
				listValues
			}
		case types.LIST_VALUES_DELETE_FAILURE:
			return state
		default:
			return state
	}
}

export default chooser
