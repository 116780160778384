import { useState, useEffect } from 'react'

const getImageIndex = (images, calcIndex) => {
	let newImageIndex

	if (calcIndex < 0)
		newImageIndex = images.length - 1
	else if (calcIndex >= images.length)
		newImageIndex = 0
	else
		newImageIndex = calcIndex
	
	return newImageIndex
}

function useImages(images, delay=10000) {
	const [imageOptions, setImageOptions] = useState({ index: 0, direction: 1 })

	useEffect(() => {
		const tid = setTimeout(() => {
			const calcIndex = imageOptions.index + imageOptions.direction
			const newIndex = getImageIndex(images, calcIndex)
	
			setImageOptions({ ...imageOptions, index: newIndex })
		}, delay)

		return () => clearTimeout(tid)
	}, [images, imageOptions, delay])

	return [images[imageOptions.index], imageOptions.index]
}

export default useImages