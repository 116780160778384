import React, { useContext, createContext } from 'react'
import { useLanguage } from 'contexts/languageContext'
import PropTypes from 'prop-types'
import _faq from 'assets/json/faq.json'

const faqContext = createContext()

export function ProvideFAQ({ children }) {
	const faq = useProvideFAQ()
	return (
		<faqContext.Provider value={faq}>{children}</faqContext.Provider>
	)
}

ProvideFAQ.propTypes = {
	children: PropTypes.object.isRequired,
}

const getFAQ = (faq, language) =>
	faq.map(item => item[language])

const useProvideFAQ = () => {
	const { language } = useLanguage()
	const faq = React.useMemo(() => getFAQ(_faq, language), [language])

	return faq
}

export const useFAQ = () => {
	return useContext(faqContext)
}
