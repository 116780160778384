import React from 'react'

import Fade from '@material-ui/core/Fade'
import PersonIcon from '@material-ui/icons/Person'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useUser } from 'contexts/userContext'
import { useText } from 'contexts/textContext'

const useStyles = makeStyles((theme) => ({
	root: {
		height: 32,
		position: 'relative',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	icon: {
		color: theme.palette.white,
		fontSize: theme.typography.h5.fontSize,
		marginRight: 4,
	},
	iconText: {
		color: theme.palette.white,
		fontSize: theme.typography.body1.fontSize,
		whiteSpace: 'nowrap',
	},
	spinner: {
		color: theme.palette.yellow,
	},
	item: {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		paddingRight: theme.spacing(3),
	},
	textPlaceholder: {
		width: 72,
		height: 10,
		backgroundColor: theme.palette.black,
		position: 'relative',
		overflow: 'hidden',
	},
	innerFlow: {
		position: 'absolute',
		backgroundColor: 'rgb(54,51,52)',
		height: '100%',
		borderRadius: '10% 0 0 10%',
	},
	imagePlaceHolder: {
		height: 28,
		width: 28,
		borderRadius: '100%',
		backgroundColor: theme.palette.black,
		position: 'relative',
		overflow: 'hidden',
	},
}))

function AuthTab() {
	const user = useUser()
	const classes = useStyles()
	const text = useText()

	const authState =
		user === false ? 'loading' : user == null ? 'not-auth' : 'auth'

	return (
		<div className={classes.root}>
			<Fade in={authState === 'loading'}>
				<div className={classes.item}>
					<span className={classes.textPlaceholder}>
						<div className={clsx(classes.innerFlow, 'pulse')}></div>
					</span>
					&nbsp;&nbsp;
					<div className={classes.imagePlaceHolder}>
						<div className={clsx(classes.innerFlow, 'pulse')}></div>
					</div>
				</div>
			</Fade>

			<Fade in={authState === 'not-auth'}>
				<div className={classes.item}>
					<PersonIcon className={classes.icon} />
					<span className={classes.iconText}>{text.start_session}</span>
				</div>
			</Fade>

			<Fade in={authState === 'auth'}>
				<div className={classes.item}>
					{user && (
						<>
							<span className={classes.iconText}>{user.displayName}</span>
							&nbsp;&nbsp;
							<img
								src={user.photoURL}
								style={{
									height: 28,
									width: 'auto',
									borderRadius: '100%',
								}}
							></img>
						</>
					)}
				</div>
			</Fade>
		</div>
	)
}

export default AuthTab
