import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'


const CustomTooltip = withStyles((theme) => ({
	arrow: {
		color: theme.palette.black,
	},
	tooltip: {
		backgroundColor: theme.palette.black,
		fontSize: theme.typography.body1.fontSize,
	},
}))((props) => <Tooltip arrow {...props} />)

export default CustomTooltip