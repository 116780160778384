import { useState, useEffect, useCallback } from 'react'
import useTabs from 'hooks/useTabs'


const isHere = (id) => {
	const scrolled = document.body.scrollTop || document.documentElement.scrollTop
	const anchor = document.querySelector(id)
	if (!anchor) return false

	const offset = anchor.offsetTop
	return scrolled > offset 
}

function useScrollLocation() {
	const tabs = useTabs()
	const [tab, setTab] = useState(false)

	const handleScroll = useCallback(() => {
		// lazy way of stopping when true
		let latest = false
		tabs.forEach(t => {
			const here = isHere(t.id)
			latest = !here ? (latest ? latest : false) : t
		})
		
		if (latest && latest.id !== tab.id) setTab(latest)
	}, [tabs, tab])

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, true)
		
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [handleScroll])

	return tab
}

export default useScrollLocation
