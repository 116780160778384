import firebase from 'firebase/app'

export const emailSignUp = ({ email, password }) =>
	firebase.auth().createUserWithEmailAndPassword(email, password)

export const emailSignIn = ({ email, password }) =>
	firebase.auth().signInWithEmailAndPassword(email, password)

export const signOut = () => firebase.auth().signOut()

export const googleSignIn = () => {
	let provider = new firebase.auth.GoogleAuthProvider()

	return firebase
		.auth()
		.signInWithPopup(provider)
		.then((result) => {
			const credential = result.credential
			console.log(credential.accessToken)
			console.log(result.user)
		})
		.catch((error) => {
			console.log(error)
		})
}

export const facebookSignIn = () => {
	let provider = new firebase.auth.FacebookAuthProvider()

	return firebase
		.auth()
		.signInWithPopup(provider)
		.then((result) => {
			const credential = result.credential
			console.log(credential.accessToken)
			console.log(result.user)
		})
		.catch((error) => {
			console.log(error)
		})
}
