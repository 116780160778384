import React from 'react'

import Home from 'pages/Home'
import Quote from 'pages/Quote'
import Suggestions from 'pages/Suggestions'
import Promotions from 'pages/Promotions'
import Terms from 'pages/Terms'
import Privacy from 'pages/Privacy'
import RentalForm from 'pages/RentalForm'
import FAQ from 'pages/FAQ'

import Slider from 'components/Slider'
import WrappedChooser from 'components/WrappedChooser'
import Contact from 'components/Contact'

export const tabs = [
	{
		id: '#b',
		name: 'Servicios',
	},
	{
		id: '#c',
		name: 'Calculadora',
	},
	{
		id: '#d',
		name: 'Nosotros',
	},
	{
		id: '#e',
		name: 'Contacto',
	},
	{
		path: ['solicitud-servicio', 'rental-form'],
		name: 'Solicitud',
	},
	{
		path: ['promociones', 'promotions'],
		name: 'Promociones',
		fancy: true,
		notDot: true,
	},
]

export const publicRoutes = [
	{
		path: ['/'],
		component: Home,
		components: [
			{
				id: '#b',
				component: Slider,
				props: {
					colors: ['blue', 'yellow', 'red'],
					views: [
						{
							header: 'Servicios',
							content: (
								<div>
									<p>
										Nuestro servicio está basado en las necesidades reales de
										nuestros clientes. Adaptando nuestro espacio a sus
										requerimientos y horarios.
									</p>

									<ul>
										{[
											'Espacios individuales con total privacidad.',
											'Renta mínima de un (1) mes.',
											'Clima y humedad estabilizados.',
											'Flexibilidad en cambios rápido a locales de otros tamaños.',
											'Variedad de tamaños desde 3 hasta 80 metros cuadrados (m²).',
											'Control de Acceso automatizados y cámaras de grabación 24/7.',
										].map((item, i) => (
											<li
												key={i}
												style={{ display: 'flex', alignItems: 'flex-start' }}
											>
												<div style={{ margin: '0px 4px' }}>&bull;</div>
												<div>{item}</div>
											</li>
										))}
									</ul>
								</div>
							),
						},
						{
							header: 'Ventajas y facilidades',
							content: (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										height: '100%',
										wrap: 'nowrap',
									}}
								>
									<ul>
										{[
											'Pagas por el tiempo y tamaño que necesites.',
											'Reduce tus costos de almacenaje.',
											'Áreas de Cargas techadas con capacidad para camiones y Colas.',
											'Parqueos con espacio para Giro de Camiones.',
											'Acceso fuera de Horario sin previo aviso.',
											'Variedades de equipos para cargar y mover las mercancías.',
											'Pasillos amplios para fácil acceso de mobiliarios grandes.',
											'Opción de cierre hermético que No permite contaminación de olores.',
										].map((item, i) => (
											<li
												key={i}
												style={{ display: 'flex', alignItems: 'flex-start' }}
											>
												<div style={{ margin: '0px 4px' }}>&bull;</div>
												<div>{item}</div>
											</li>
										))}
									</ul>
								</div>
							),
						},
						{
							header: 'City Shop',
							content: (
								<div>
									<h6>
										Tienda de Accesorios con provisiones de artículos para
										empaque y almacenaje:
									</h6>

									<ul>
										{[
											'Cajas de Cartón',
											'Cinta Adhesiva',
											'Rollo de Papel para plastificar',
											'Tie wrap',
											'Marcadores',
											'Deshumidificadores',
											'Cuerdas de amarres',
											'Tijeras',
											'Candados',
											'Guantes',
											'Mascarillas para el Polvo',
											'Cuchillas para cortes',
										].map((item, i) => (
											<li
												key={i}
												style={{ display: 'flex', alignItems: 'flex-start' }}
											>
												<div style={{ margin: '0px 4px' }}>✓</div>
												<div>{item}</div>
											</li>
										))}
									</ul>
								</div>
							),
						},
					],
				},
			},
			{
				id: '#c',
				component: WrappedChooser,
				props: {},
			},
			{
				id: '#d',
				component: Slider,
				props: {
					colors: ['yellow', 'blue'],
					views: [
						{
							header: '¿Quiénes somos?',
							content: (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										height: '100%',
										wrap: 'nowrap',
									}}
								>
									<p>
										Pioneros en República Dominicana en la modalidad
										Self-Storage con cómodas y amplias instalaciones de
										almacenes diseñados a las necesidades de nuestros clientes.
									</p>

									<p>
										Hemos concebido City Storage como una empresa para dar
										servicios de almacenajes a pequeña y mediana escala, de esta
										manera funciona como un Centro Logístico para uso Comercial
										y Personal.
									</p>

									<p style={{ fontWeight: 'bold' }}>
										&ldquo;Consigue tu almacén en cuestión de minutos&rdquo;
									</p>
								</div>
							),
						},
						{
							header: 'Self storage',
							content: (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										height: '100%',
										wrap: 'nowrap',
									}}
								>
									<p>
										City Storage es un lugar donde puedes almacenar y manejar tu
										mercancía, en un área segura, cómoda y de fácil acceso,
										donde colocas tu candado y obtienes un código personalizado
										para cada usuario, permitiéndote entrar a tu almacén cuando
										quieras, sin previo aviso y sin cargos adicionales.
									</p>

									<p>
										Puedes almacenar una gran variedad de artículos de
										accesorios, muebles y objetos personales, como archivo
										muerto, material promocional, mobiliarios de oficina,
										equipos, mercancías de negocio, entre otros.
									</p>
								</div>
							),
						},
					],
				},
			},
			{
				id: '#e',
				component: Contact,
				props: {},
			},
		],
	},
	{
		path: ['/cotizar', '/quote'],
		component: Quote,
	},
	{
		path: ['/sugerencias', 'suggestions'],
		component: Suggestions,
	},
	{
		path: ['/promociones', '/promotions'],
		component: Promotions,
	},
	{
		path: ['/privacidad', '/privacy'],
		component: Privacy,
	},
	{
		path: ['/condiciones', '/conditions'],
		component: Terms,
	},
	{
		path: ['/solicitud-servicio', '/rental-form'],
		component: RentalForm,
	},
	{
		path: ['/preguntas-frecuentes', '/faq'],
		component: FAQ,
	},
]

export const footer = [
	{
		pathKey: '/faq',
		name: 'Preguntas frecuentes',
	},
	{
		pathKey: '/rental-form',
		name: 'Solicitud de Servicio',
	},
	{
		pathKey: '/privacy',
		name: 'Políticas de Privacidad',
	},
	{
		pathKey: '/conditions',
		name: 'Términos y Condiciones',
	},
	{
		pathKey: '/quote',
		name: 'Cotizar',
	},
	{
		pathKey: '/suggestions',
		name: 'Sugerencias',
	},
	{
		pathKey: '/promotions',
		name: 'Promociones',
	},
	{
		pathKey: '/',
		name: 'Inicio',
	},
]
