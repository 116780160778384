import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import { signOut } from 'auth/auth'
import { useText } from 'contexts/textContext'

const useStyles = makeStyles((theme) => ({
	sessionButton: {
		backgroundColor: theme.palette.red,
		color: theme.palette.white,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '.5rem',
		transition: '.3s',
		cursor: 'pointer',
		'&:hover': {
			transition: '.3s',
			backgroundColor: theme.palette.darkRed,
		},
	},
}))

function AuthedNavMenu({ exit }) {
	const classes = useStyles()
	const text = useText()

	const handleExit = React.useCallback(() => {
		exit()
		signOut()
	}, [exit])

	return (
		<Grid
			container
			spacing={1}
			style={{ margin: 0 }}
		>
			<Grid item xs={12}>
				<button className={classes.sessionButton} onClick={handleExit}>
					{text.end_session}
				</button>
			</Grid>
		</Grid>
	)
}

AuthedNavMenu.propTypes = {
	exit: PropTypes.func.isRequired,
}

export default AuthedNavMenu
