import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import MChip from 'components/MChip'

import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import Loader from 'pages/Loader'

import { useRoutes } from 'contexts/routesContext'
import { useLanguage } from 'contexts/languageContext'
import { useText } from 'contexts/textContext'


const useStyles = makeStyles(() => ({
	container: {
		minHeight: '100vh',
		overflow: 'hidden',
		position: 'relative',
	},
}))

function Main() {
	const text = useText()
	const { language, switchLanguage } = useLanguage()
	const { routes, routesMap, footer } = useRoutes()
	const classes = useStyles()

	if (!text || !language || !switchLanguage || !routes || !routesMap || !footer) {
		return <Loader />
	}

	return (
		<div>
			<Navbar />

			<div className={classes.container}>
				<Switch>
					{routes.map((route) => (
						<Route
							exact={route.path[0] === '/'}
							path={route.path}
							component={() => <route.component {...route} />}
							key={route.path[0]}
						/>
					))}
					<Route component={() => <MChip style={{ marginTop: '20rem' }} />} />
				</Switch>
			</div>

			<Footer />
		</div>
	)
}

export default Main