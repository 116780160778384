import React, { useState, useEffect, useContext, createContext } from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase/app'

import defaultPhoto from 'assets/icons/logo-cropped.png'

const userContext = createContext()

export function ProvideUser({ children }) {
	const user = useProvideUser()
	return <userContext.Provider value={user}>{children}</userContext.Provider>
}

ProvideUser.propTypes = {
	children: PropTypes.object.isRequired
}

export const useUser = () => {
	return useContext(userContext)
}

function useProvideUser() {
	const [user, setUser] = useState(false)

	useEffect(() => {
		const unsubscribe = firebase.auth().onAuthStateChanged(u => {
			if (u) {
				console.log('user authed')
				let displayName = u.displayName
				let photoURL = u.photoURL
				if (!u.displayName || u.displayName === '')
					displayName = u.email
				if (!u.photoURL || u.photoURL === '')
					photoURL = defaultPhoto

				setUser({
					...u,
					displayName,
					photoURL,
				})
			} else {
				console.log('user not authed')
				setUser(null)
			}
		})

		return () => unsubscribe()
	}, [])

	return user
}
