import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { scrollTo } from 'assets/js/utils'

import promo from 'assets/images/promo.png'
import { useRoutes } from 'contexts/routesContext'
import { useText } from 'contexts/textContext'

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 144 + 64,
	},
	promo: {
		width: '100%',
		display: 'block',
	},
	text: {
		color: theme.palette.white,
		fontSize: theme.typography.body2.fontSize - 2,
		padding: theme.spacing(1.5),
		textAlign: 'center',
		textDecoration: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	left: {
		backgroundColor: theme.palette.yellow,
	},
	right: {
		backgroundColor: theme.palette.green,
		fontWeight: 900,
		fontSize: theme.typography.h6.fontSize,
		cursor: 'pointer',
		'&:hover': {
			color: theme.palette.yellow,
		},
	},
}))

function Promotions() {
	const classes = useStyles()
	const { routesMap } = useRoutes()
	const text = useText()

	React.useEffect(() => {
		scrollTo('#a', 0, 500)
	}, [])

	return (
		<div className={classes.root}>
			<Container maxWidth='md'>
				<img src={promo} className={classes.promo} />
				<Grid container>
					<Grid
						item
						xs={12}
						sm={8}
						md={9}
						className={clsx(classes.text, classes.left)}
					>
						{text.calculator_text}
					</Grid>
					<Grid
						item
						xs={12}
						sm={4}
						md={3}
						className={clsx(classes.text, classes.right)}
						component={Link}
						to={routesMap['/quote']}
					>
						{text.quote}
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default Promotions
