import { combineReducers } from 'redux'

import options from './options'
import chooser from './chooser'
import bubbles from './bubbles'

const rootReducer = combineReducers({
	options,
	chooser,
	bubbles,
})

export default rootReducer
