import React from 'react'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'

import { makeStyles } from '@material-ui/core/styles'

import { scrollTo } from 'assets/js/utils'
import { useLanguage } from 'contexts/languageContext'

const useStyles = makeStyles((theme) => ({
	root: {
		'& h4': {
			fontSize: theme.typography.h4.fontSize,
		},
	},
	container: {
		backgroundColor: theme.palette.white,
		marginTop: '16rem',
		padding: theme.spacing(12, 4, 12, 4),
	},
	divider: {
		width: '100%',
		margin: theme.spacing(1, 0),
	},
	content: {
		fontSize: theme.typography.body1.fontSize,
		'& *': {
			fontSize: theme.typography.body1.fontSize,
		},
		'& > ul': {
			listStyleType: 'upper-roman',
			margin: theme.spacing(0, 3),
		},
		'& > ul:last-child': {
			listStyleType: 'lower-alpha',
			margin: theme.spacing(0, 3),
		},
		'& b': {
			fontWeight: 'bold',
		},
		'& li > ul': {
			listStyleType: 'decimal',
			margin: theme.spacing(0, 3),
		},
	},
}))

function Terms() {
	const classes = useStyles()
	const { language } = useLanguage()

	React.useEffect(() => {
		scrollTo('#a', 0, 500)
	}, [])

	if (language === 'es')
		return (
			<Container maxWidth='md' className={classes.root}>
				<div className={classes.container}>
					<h4>Términos y Condiciones</h4>
					<Divider className={classes.divider} />

					<div className={classes.content}>
						Puede almacenar prácticamente cualquier cosa, casi sin importar el
						tamaño, desde gabinetes grandes, armarios, camas, archivos de
						empresas, ordenadores, bicicletas, hasta cualquier tipo de mueble,
						siempre y cuando no se contemple en lo prohibido en el punto V.
						<ul>
							<li>
								El <b>Candado del Almacén</b> es propiedad del Inquilino para
								ser fijado en la puerta. Solo tiene derecho a un candado por
								puerta.
							</li>
							<li>
								El <b>Horario de acceso</b> las instalaciones será: de lunes a
								viernes de 7:00 a.m. a 9:30 p.m. los siete (7) días de la
								semana.
							</li>
							<li>
								En el almacenamiento La Propietaria no tiene ningún{' '}
								<b>Control</b> sobre detalles, valores, cantidades, tipos y
								objetos almacenados; de forma que no se hace responsible por la
								pérdida o deterioro de los mismos, para los casos de robo,
								incendio y daños ocurridos a los artículos y mercancías
								almacenadas.
							</li>
							<li>
								El <b>Uso</b> exclusivamente de almacenamiento de materiales,
								mercancías y/o artículos no perecederos, no pudiendo dedicarlo a
								otro uso, ni cederlo, ni subalquilarlo en todo o en parte.
							</li>
							<li>
								Está <b>Prohibido</b> almacenar materiales, sustancias u objetos
								prohibidos por ley o peligrosos, que de forma enunciativa y no
								limitativa se indican a continuación:
								<ul>
									<li>Dinero en efectivo o en papel</li>
									<li>
										Cualquier documento negociable o de comercio, títulos.
									</li>
									<li>Obras de arte</li>
									<li>Prendas, joyas o metales preciosos</li>
									<li>Sustancias corrosivas</li>
									<li>Sustancias inflamables</li>
									<li>Irritantes</li>
									<li>Disolventes</li>
									<li>Químicos</li>
									<li>
										Bebidas alcohólicas, drogas, medicamentos u otras sustancias
										controladas
									</li>
									<li>Armas de fuego</li>
									<li>Animales</li>
									<li>
										Materiales inflamables explosivos (fuegos artificiales) o
										antihigiénicos
									</li>
									<li>Materiales tóxicos</li>
									<li>Gases</li>
									<li>Productos perecederos y de consumo humano</li>
								</ul>
							</li>
							<li>
								<b>Derecho de Inspección del Almacén. -</b>
								EL INQUILINO reconoce y acepta que LA PROPIETARIA tiene el
								derecho de inspeccionar el Almacén, dentro de las veinticuatro
								(24) horas de la solicitud de inspección de LA PROPIETARIA. EL
								INQUILINO deberá presentarse a las instalaciones de LA
								PROPIETARIA y abrir el Almacén en presencia del representante
								del mismo y de cualquier persona que LA PROPIETARIA es
								pertinente. De no presentarse, LA PROPIETARIA se reserva el
								derecho de terminar el contrato y proceder a lo previsto en el
								artículo 6.2 del mismo.
							</li>
							<li>
								<b>Inspección por Autoridad Pública u Orden Judicial. -</b>
								Queda entendido entre las partes, que LA PROPIETARIA debe
								obtemperar toda solicitud de una autoridad pública competente u
								orden judicial, es decir, el acceso al almacén para inspección
								y/o incautación de la mercancía almacenada, en cuyo caso EL
								INQUILINO libera a LA PROPIETARIA de toda responsabilidad
								asociada y por la rotura del candado, pérdida, deterioro o
								destrucción de los materiales almacenados.
							</li>
						</ul>
						<br />
						<br />
						<div className={classes.h4}>Obligaciones de City Storage</div>
						<Divider className={classes.divider} />
						<ul>
							<li>
								<b>Obligaciones Principales de LA PROPIETARIA. -</b>
								LA PROPIETARIA se obliga frente a EL INQUILINO a lo siguiente:
								<ul>
									<li>
										Permitir el acceso a(los) Almacén(es) en el horario
										acordado.
									</li>
									<li>
										Proporcionar los servicios de mantenimiento, iluminación,
										vigilancia y tratamiento contra plagas al inmueble en el
										lugar donde se encuentra el Almacén.
									</li>
									<li>
										Cubrir hasta la suma de VEINTICINCO MIL PESOS DOMINICANOS
										(RD$25,000.00), en caso de robo, incendio y daños ocurridos
										a los artículos y mercancías almacenadas por EL INQUILINO,
										siempre que no se deba a faltas imputables a EL INQUILINO.
									</li>
								</ul>
							</li>
							<li>
								<b>Obligaciones Principales del INQUILINO:</b>
								<ul>
									<li>Mantener el almacén en buen estado.</li>
									<li>
										Pagar el importe del alquiler en la fecha y lugar acordados.
									</li>
									<li>Respetar las prohibiciones de uso del almacén.</li>
									<li>
										Permitir el acceso al almacén a solicitud de inspecciones de
										LA PROPIETARIA, Autoridades Públicas u Orden Judicial.
									</li>
									<li>
										Contratar, a su discreción, por su cuenta y costo una póliza
										de seguro que cubra daños y pérdida de su mercancía
										almacenada.
									</li>
									<li>
										Responder a LA PROPIETARIA y demás inquilinos por daños que
										su mercancía almacenada ocasiones a la propiedad, a
										mercancía de otros inquilinos y/o a terceros.
									</li>
									<li>
										Mantener indemne a LA PROPIETARIA por el almacenamiento de
										sustancias controladas prohibidas por ley.
									</li>
									<li>
										No ceder ni subalquilar, en todo o en parte, el Almacén.
									</li>
									<li>
										No realizar cambios en la estructura y distribución del
										almacén.
									</li>
									<li>
										Entregar debidamente limpio y desocupado el Almacén, del
										acuerdo de servicios.
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</Container>
		)
	else if (language === 'en')
		return (
			<Container maxWidth='md' className={classes.root}>
				<div className={classes.container}>
					<h4>Terms and Conditions</h4>
					<Divider className={classes.divider} />

					<div className={classes.content}>
						You can store almost anything, only taking in account the size of
						the objects in some cases. From large cabinets, beds, files,
						computers, bicycles and almost any type of furniture, as long as it
						isn’t contemplated in what is prohibited in point V of these
						conditions.
						<ul>
							<li>
								The <b>Warehouse padlock</b>. The padlock belongs to the tenant,
								to be placed at the doorway. The tenant is only allowed one lock
								per door.
							</li>
							<li>
								The <b>Access Hours</b> to the warehouse is: from 7:00 am to
								9:30 pm, the 7 days of the week.
							</li>
							<li>
								In the storage, the owner has no <b>control</b> over the
								details, values, quantity or types of objects that are stored,
								so he is not responsible for damage or loss of the merchandise
								in the warehouse resulting from robbery, fire or other causes.
							</li>
							<li>
								The <b>use</b> is exclusively for storage of goods, merchandise
								and/or non-perishable items, not allowed to use it for any other
								use, or sublet it partially or completely.
							</li>
							<li>
								It is <b>forbidden</b> to store materials, substance or objects
								forbidden by law or that are dangerous, that are listed and not
								limited to the following:
								<ul>
									<li>Cash or paper value</li>
									<li>Any negotiable or commercial document, titles.</li>
									<li>Artworks</li>
									<li>Precious metals, jewelry or garments</li>
									<li>Corrosive substance</li>
									<li>Inflammable substance</li>
									<li>Irritants</li>
									<li>Solvents</li>
									<li>Chemical</li>
									<li>
										Alcoholic beverages, drugs, medicine or other controlled
										substances
									</li>
									<li>Firearms</li>
									<li>Animals</li>
									<li>
										Inflammable or explosives materials (Fireworks), fuels and
										Non-hygienic
									</li>
									<li>Toxic Materials</li>
									<li>Gases</li>
								</ul>
							</li>
							<li>
								<b>Warehouse inspection right</b> – THE CLIENT acknowledges and
								accepts that THE OWNER has the right to inspect the warehouse,
								within the twenty four (24) hours of receiving the request for
								inspection on behalf of THE OWNER. THE CLIENT must be present at
								the warehouse, opening the storage space in presence of THE
								OWNER&apos;S representative and anyone THE OWNER considers
								pertinent. If he does not show up, THE OWNER reserves the right
								to end the warehouse service agreement and proceed to article
								6.2. of said agreement.
							</li>
							<li>
								<b>Inspection by Public Authority or Court Order</b> – It is
								understood between the parties, that THE OWNER must obtain all
								request from a competent public authority or court order that
								is, access to the warehouse for inspection and/or seizure of the
								stored merchandise, in which case THE CLIENT frees THE OWNER of
								all responsibilities associated to the breaking of the padlock,
								loss, damage or destruction of the stored merchandise.
							</li>
						</ul>
						<br />
						<br />
						<h4>City Storage Obligations</h4>
						<Divider className={classes.divider} />
						<ul>
							<li>
								<b>Main obligations of THE OWNER</b> – THE OWNER is obliged in
								before THE CLIENT to the following:
								<ul>
									<li>
										Allowing access to the storage during the established
										schedule.
									</li>
									<li>
										Provide maintenance, lighting, surveillance and pest control
										to the property where the warehouse is located.
									</li>
									<li>
										Cover up to amount of TWENTY FIVE THOUSAND DOMINICAN PESOS
										(RD$25,000.00), in the case of theft, fire or damages caused
										to the merchandise or articles stored by THE TENANT, as long
										as it is not due to faults attributable to THE TENANT.
									</li>
								</ul>
							</li>
							<li>
								<b>Main obligations of THE CLIENT</b>
								<ul>
									<li>Keep the space in optimum conditions.</li>
									<li>
										Pay the amount for the storage service by the date and place
										agreed upon.
									</li>
									<li>Respect the prohibitions of use of the warehouse.</li>
									<li>
										Allow access to the warehouse upon request of THE OWNER,
										Public Authorities or a Court Order.
									</li>
									<li>
										Take on, on his sole discretion, and covered on his own
										cost, an insurance policy to cover damages and losses of
										your stored merchandise.
									</li>
									<li>
										Answer to THE OWNER and other tenants for any damages that
										his stored merchandise causes the property, to others
										merchandise and/or to a third party.
									</li>
									<li>
										Indemnify THE OWNER for the storage of controlled substances
										prohibited by law.
									</li>
									<li>No to lend o sublet, in part or whole, the Warehouse.</li>
									<li>
										Not to carry out changes in the structure or the
										distribution of the warehouse.
									</li>
									<li>
										Return the warehouse properly clean and unoccupied, in
										accordance with the service agreement.
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</Container>
		)
}

export default Terms
