import types from '../types.json'

export const updateOptions = (option) => (dispatch) => {
	const data = option

	if (data)
		return dispatch({ type: types.OPTIONS_UPDATE_SUCCESS, payload: data })
	return dispatch({
		type: types.OPTIONS_UPDATE_FAILURE,
		error: 'Invalid data of null.'
	})
}