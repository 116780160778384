import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import { blueGrey } from '@material-ui/core/colors'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Provider } from 'react-redux'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ProvideUser } from 'contexts/userContext'
import { ProvideLanguage } from 'contexts/languageContext'
import { ProvideText } from 'contexts/textContext'
import { ProvideFAQ } from 'contexts/faqContext'
import { ProvideRoutes } from 'contexts/routesContext'

import store from 'store/store'
import Main from 'views/Main'

import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = JSON.parse(process.env['REACT_APP_FIREBASE_CONFIG'])
firebase.initializeApp(firebaseConfig)

const theme = createMuiTheme({
	palette: {
		...blueGrey,
		yellow: 'rgb(243,187,68)',
		darkYellow: 'rgb(232,151,62)',
		blue: 'rgb(67,153,213)',
		darkBlue: 'rgb(40,64,148)',
		green: 'rgb(126,161,69)',
		red: 'rgb(222,94,73)',
		darkRed: 'rgb(220,79,65)',
		white: 'rgb(255,255,255)',
		black: 'rgb(34,31,32)',
		gray: 'rgb(187,189,191)',
		darkGray: 'rgb(127,129,131)',
		textBackground: 'rgb(241,241,242)',
		footer: 'rgb(245,246,248)',
	},
	typography: {
		body1: {
			fontSize: 16,
		},
		body2: {
			fontSize: 14,
		},
		h2: {
			fontSize: 56,
		},
	},
})

function App() {
	const ref = React.createRef()

	return (
		<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
			<SnackbarProvider ref={ref} maxSnack={3}>
				<ProvideUser>
					<ProvideLanguage>
						<ProvideText>
							<ProvideFAQ>
								<ProvideRoutes>
									<Provider store={store}>
										<ThemeProvider theme={theme}>
											<Router>
												<Main />
											</Router>
										</ThemeProvider>
									</Provider>
								</ProvideRoutes>
							</ProvideFAQ>
						</ProvideText>
					</ProvideLanguage>
				</ProvideUser>
			</SnackbarProvider>
		</GoogleReCaptchaProvider>
	)
}

export default App
