import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: '10rem',
	},
	inner: {
		padding: '1rem 4rem',
		backgroundColor: theme.palette.red,
		color: theme.palette.white,
		'& span': {
			fontWeight: 'bold',
		}
	},
}))

function MChip(props) {
	const classes = useStyles()
	const [msg] = React.useState(props.text ? <div>The <span>{props.text}</span> page is under development.</div> : <div>No pudimos encontrar esta página.</div>)

	return (
		<Grid container justify='center' alignItems='center' className={classes.root} style={props.style}>
			<Grid item>
				<div className={classes.inner}>{msg}</div>
			</Grid>
		</Grid>
	)
}

MChip.propTypes = {
	text: PropTypes.string,
	style: PropTypes.object
}

export default MChip