import React, { useContext, createContext } from 'react'
import PropTypes from 'prop-types'

import { useSnackbar } from 'notistack'

import { privateRoutes } from 'routes/privateRoutes'
import { publicRoutes, footer } from 'routes/publicRoutes'
import { authRoutes } from 'routes/authRoutes'

import { useUser } from 'contexts/userContext'
import { useLanguage } from 'contexts/languageContext'
import { useText } from 'contexts/textContext'

const routesContext = createContext()

export function ProvideRoutes({ children }) {
	const value = useProvideRoutes()
	return (
		<routesContext.Provider value={value}>{children}</routesContext.Provider>
	)
}

ProvideRoutes.propTypes = {
	children: PropTypes.object.isRequired,
}

const getRoutesMap = allRoutes => {
	let enRoutes = {}
	let esRoutes = {}

	for (let routes of allRoutes) {
		for (let route of routes.en) {
			const paths = route.path.slice()
			enRoutes[route.path[0]] = paths[0]
			esRoutes[route.path[0]] = paths.reverse()[0]
		}
	}

	return { en: enRoutes, es: esRoutes }
}

const getFlatRoutes = (allRoutes, language) => allRoutes.reduce((array, routes) => ([ ...array, ...routes[language] ]), [])

const useProvideRoutes = () => {
	const user = useUser()
	const { language } = useLanguage()
	const text = useText()

	const { enqueueSnackbar } = useSnackbar()

	const [routes, routesMap] = React.useMemo(() => {
		let allRoutes = []

		if (user) {
			allRoutes = [publicRoutes, privateRoutes]
			
			enqueueSnackbar(`${text.welcome[0]} ${user.displayName}${text.welcome[1]}`, { variant: 'info', preventDuplicate: true })
		} else {
			allRoutes = [publicRoutes, authRoutes]
		}

		const flatRoutes = getFlatRoutes(allRoutes, language)
		const newRoutesMap = getRoutesMap(allRoutes)

		return [flatRoutes, newRoutesMap[language]]
	}, [language, user, text, enqueueSnackbar])

	return { routes, routesMap, footer: footer[language] }
}

export const useRoutes = () => {
	return useContext(routesContext)
}
