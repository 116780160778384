import React from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory, useLocation } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Collapse from '@material-ui/core/Collapse'
import Tabs from '@material-ui/core/Tabs'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import logo from 'assets/icons/logo.png'
import ten_years from 'assets/icons/ten_years.png'
import Banner from 'components/Banner'
import useScrollLocation from 'hooks/useScrollLocation'
import Drawer from '@material-ui/core/Drawer'

import { scrollTo } from 'assets/js/utils'
import { useUser } from 'contexts/userContext'
import { useLanguage } from 'contexts/languageContext'
import AuthedNavMenu from 'components/AuthedNavMenu'
import PublicNavMenu from 'components/PublicNavMenu'
import AuthTab from 'components/AuthTab'
import clsx from 'clsx'

import { updateBubbles } from 'store/actions/bubbles'
import { useDispatch } from 'react-redux'
import useTabs from 'hooks/useTabs'

const StyledTabs = withStyles(() => ({
	indicator: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
		padding: 0,
		margin: 0,
	},
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const useTabStyles = makeStyles((theme) => ({
	root: {
		minWidth: 0,
		opacity: 1,
		cursor: 'pointer',
		textTransform: 'none',
		color: theme.palette.white,
		textDecoration: 'none',
		padding: 0,
		marginLeft: theme.spacing(2.5),
		'& .MuiTab-wrapper': {
			borderBottom: '4px solid transparent',
			fontSize: theme.typography.body1.fontSize,
			padding: theme.spacing(0.5, 0, 0.3, 0),
		},
		'&:hover': {
			'& .MuiTab-wrapper': {
				borderBottom: `4px solid ${theme.palette.red} !important`,
			},
		},
		'&$selected': {
			'& *': {},
		},
	},
	fancy: {
		minWidth: 0,
		opacity: 1,
		cursor: 'pointer',
		textTransform: 'none',
		color: theme.palette.white,
		textDecoration: 'none',
		marginLeft: theme.spacing(2),
		backgroundColor: 'transparent',
		'& .MuiTab-wrapper': {
			fontWeight: 'bold',
			borderBottom: '4px solid transparent',
			fontSize: theme.typography.body1.fontSize,
			padding: theme.spacing(0.5, 1.5, 0.3, 1.5),
		},
		'&:hover': {
			'& .MuiTab-wrapper': {
				backgroundColor: theme.palette.red,
				// backgroundColor: theme.palette.red,
				// borderBottom: `4px solid ${theme.palette.white} !important`,
			},
		},
		'&$selected': {
			'& *': {},
		},
	},
}))

const StyledTab = (props) => {
	const classes = useTabStyles()

	return (
		<Tab
			{...props}
			disableRipple
			className={clsx(props.fancy ? classes.fancy : classes.root)}
		/>
	)
}

StyledTab.propTypes = {
	fancy: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
	menuButton: {
		color: theme.palette.black,
		backgroundColor: theme.palette.white,
		borderRadius: 0,
		'& *': {
			fontSize: theme.typography.h4.fontSize,
		},
	},
	toolbar: {
		alignItems: 'center',
		padding: theme.spacing(4, 2),
		// backgroundColor: 'transparent !important',
		background: 'linear-gradient(rgba(34,31,32,.6) 70%,transparent)',
		[theme.breakpoints.down('sm')]: {
			background: 'linear-gradient(rgba(34,31,32,.6) 80%,transparent)',
		},
	},
	bottom: {
		padding: 0,
		backgroundColor: 'transparent',
	},
	logoWrapper: {
		display: 'flex',
		alignItems: 'flex-start',

		'& > img:not(:last-child)': {
			marginRight: theme.spacing(1),
		},
	},
	logo: {
		height: 55,
		width: 'auto',
	},
	logoTenYears: {
		height: 51,
		width: 'auto',
	},
	noRight: {
		[theme.breakpoints.up('xs')]: {
			paddingRight: '0px !important',
		},
	},
	appBar: {
		backgroundColor: 'transparent',
	},
	vcenter: {
		display: 'flex',
		alignItems: 'center',
	},
	textNotWhite: {
		'& *': {
			color: `black !important`,
		},
	},
	dropdown: {
		backgroundColor: theme.palette.white,
		marginTop: 2,
		width: 256,
		maxWidth: '100%',
		padding: theme.spacing(1, 3, 1, 1),
	},
	tabsContainer: {
		height: '100%',
		padding: theme.spacing(2),
	},
	tab: {
		border: `1px solid ${theme.palette.gray}`,
		color: theme.palette.black,
		padding: theme.spacing(1),
		textAlign: 'center',
	},
	fancyMobile: {
		border: `1px solid transparent`,
		color: theme.palette.white,
		backgroundColor: theme.palette.red,
	},
	weirdPadding: {
		paddingRight: theme.spacing(1.6),
	},
	containerSpacing: {
		padding: theme.spacing(0, 0, 0, 3),
	},
	languageContainerMobile: {
		color: theme.palette.black,
		cursor: 'pointer',
		userSelect: 'none',
		fontSize: theme.typography.body2.fontSize,
		'& > span': {
			fontSize: theme.typography.body2.fontSize,
		},
	},
	languageContainer: {
		color: theme.palette.white,
		cursor: 'pointer',
		userSelect: 'none',
		fontSize: theme.typography.body2.fontSize,
		'& > span': {
			fontSize: theme.typography.body2.fontSize,
		},
	},
	activeLanguage: {
		color: theme.palette.red,
	},
	navRightPad: {
		marginRight: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
		},
	},
}))

function Navbar() {
	const dispatch = useDispatch()
	const classes = useStyles()
	const { language, switchLanguage } = useLanguage()
	const scrollTab = useScrollLocation()
	const history = useHistory()
	const user = useUser()
	const tabs = useTabs()
	const location = useLocation()

	const [isShowing, setShowing] = React.useState(false)
	const [drawerOpen, setDrawerOpen] = React.useState(false)
	const [open, setOpen] = React.useState(false)

	const enter = () => setOpen(true)
	const exit = () => setOpen(false)

	React.useEffect(() => {
		setTimeout(() => {
			setShowing(true)
		}, 750)
	}, [])

	const handleSwitchLanguage = React.useCallback(() => {
		switchLanguage()

		if (location.pathname === '/' && location.hash) history.push('/')
	}, [switchLanguage, history, location])

	const toggleDrawer = React.useCallback(
		(newDrawerOpen) => (event) => {
			if (
				event.type === 'keydown' &&
				(event.key === 'Tab' || event.key === 'Shift')
			)
				return

			if (isShowing) setShowing(false)

			setDrawerOpen(newDrawerOpen)
		},
		[isShowing]
	)

	const handleDrawerTabClick = (tab) => (e) => {
		toggleDrawer(false)(e)

		if (tab.id) scrollTo(tab.id, 0, 333)
	}

	const handleTabClick = React.useCallback(
		(tab) => {
			if (tab.id) {
				dispatch(updateBubbles(tab.id))
				scrollTo(tab.id)
			}
		},
		[dispatch]
	)

	return (
		<>
			<Drawer anchor='right' open={drawerOpen} onClose={toggleDrawer(false)}>
				<div
					style={{ width: 250, height: '100%' }}
					onKeyDown={toggleDrawer(false)}
				>
					<Grid
						container
						direction='column'
						justify='space-between'
						className={classes.tabsContainer}
					>
						<Grid item>
							<Grid container spacing={1}>
								{tabs.map((tab) => (
									<Grid
										item
										xs={12}
										component={Link}
										key={tab.id || tab.path[0]}
										to={`/${tab.id || tab.path[0]}`}
										onClick={handleDrawerTabClick(tab)}
										style={{ textDecoration: 'none' }}
									>
										<div
											className={clsx(
												classes.tab,
												tab.fancy && classes.fancyMobile
											)}
										>
											{tab.name}
										</div>
									</Grid>
								))}
								<Grid item container justify='center'>
									<Grid item>
										<div
											className={classes.languageContainerMobile}
											onClick={handleSwitchLanguage}
										>
											<span
												className={clsx(
													language === 'es' && classes.activeLanguage
												)}
											>
												ES
											</span>
											{' | '}
											<span
												className={clsx(
													language === 'en' && classes.activeLanguage
												)}
											>
												EN
											</span>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<div className={classes.weirdPadding}>
								{user ? (
									<AuthedNavMenu exit={exit} />
								) : (
									<PublicNavMenu exit={exit} />
								)}
							</div>
						</Grid>
					</Grid>
				</div>
			</Drawer>

			<AppBar
				id='a'
				position='absolute'
				elevation={0}
				className={classes.appBar}
				style={{ zIndex: 1000 }}
			>
				<Banner isShowing={isShowing} setShowing={setShowing} />
				<Toolbar className={classes.toolbar}>
					<Container maxWidth='md' className={classes.containerSpacing}>
						<Grid container alignItems='center' justify='space-between'>
							<Grid item component={Link} to='/' className={classes.logoWrapper}>
								<img src={logo} className={classes.logo} />
								<img src={ten_years} className={classes.logoTenYears} />
							</Grid>

							{/* only show hamburger on small screens */}
							<Hidden mdUp>
								<Grid item>
									<IconButton
										className={classes.menuButton}
										onClick={toggleDrawer(true)}
									>
										<MenuIcon />
									</IconButton>
								</Grid>
							</Hidden>

							{/* only show tabs on large screens */}
							<Hidden smDown>
								<Grid item>
									<Grid
										container
										direction='column'
										justify='space-between'
										alignItems='flex-end'
									>
										<Grid item>
											<div
												className={clsx(classes.vcenter, classes.navRightPad)}
											>
												<div
													style={{
														position: 'relative',
														userSelect: 'none',
														zIndex: 9999,
													}}
													onMouseEnter={enter}
													onMouseLeave={exit}
												>
													<AuthTab />

													<Collapse
														collapsedHeight={0}
														in={open}
														style={{
															position: 'absolute',
															right: 0,
														}}
													>
														<div className={classes.dropdown}>
															{user ? (
																<AuthedNavMenu exit={exit} />
															) : (
																<PublicNavMenu exit={exit} />
															)}
														</div>
													</Collapse>
												</div>

												<div
													className={classes.languageContainer}
													onClick={handleSwitchLanguage}
												>
													<span
														className={clsx(
															language === 'es' && classes.activeLanguage
														)}
													>
														ES
													</span>
													{' | '}
													<span
														className={clsx(
															language === 'en' && classes.activeLanguage
														)}
													>
														EN
													</span>
												</div>
											</div>
										</Grid>
										<Grid item>
											<StyledTabs value={scrollTab?.id || false}>
												{tabs.map((tab) => (
													<StyledTab
														key={tab.id || tab.path[0]}
														fancy={tab.fancy ? 1 : 0}
														value={tab.id || tab.path[0]}
														label={tab.name}
														component={Link}
														to={`/${tab.id || tab.path[0]}`}
														onClick={() => handleTabClick(tab)}
													/>
												))}
											</StyledTabs>
										</Grid>
									</Grid>
								</Grid>
							</Hidden>
						</Grid>
					</Container>
				</Toolbar>
			</AppBar>
		</>
	)
}

export default Navbar
