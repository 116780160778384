import SignUp from 'layouts/SignUp'

export const authRoutes = {
	es: [
		{
			path: ['/crear-cuenta'],
			component: SignUp
		},
	],
	en: [
		{
			path: ['/signup', '/crear-cuenta'],
			component: SignUp
		},
	],
}