import types from 'store/types.json'
import initialOptions from 'assets/json/data.json'

const initialState = {
	options: initialOptions,
	customOptions: []
}

const calculator = (state=initialState, action) => {
	switch (action.type) {
		case types.OPTIONS_UPDATE_SUCCESS:
			return {
				...state,
				customOptions: [ ...state.customOptions, action.payload ].sort((a,b) => a.name.localeCompare(b.name)) 
			}
		case types.OPTIONS_UPDATE_FAILURE:
			return state
		default:
			return state
	}
}

export default calculator
