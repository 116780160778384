export const BANNER_HEIGHT = 64
export const COLORS = [
	'yellow',
	'blue',
	'red',
	'white',
	'black',
	'gray',
	'green',
]
export const labels = [
	'1.5m x 2.35m',
	'2m x 2.35m',
	'2.5m x 2.35m',
	'3m x 2.35m',
	'4.5m x 2.35m',
	'6m x 2.35m',
	'12m x 2.35m',
]
export const units = [3.5, 4.7, 5.9, 7.1, 10.6, 14, 28]