import React, { useContext, createContext } from 'react'
import PropTypes from 'prop-types'

const languageContext = createContext()

export function ProvideLanguage({ children }) {
	const language = useProvideLanguage()
	return <languageContext.Provider value={language}>{children}</languageContext.Provider>
}

ProvideLanguage.propTypes = {
	children: PropTypes.object.isRequired
}

const useProvideLanguage = () => {
	const [language, setLanguage] = React.useState(localStorage.getItem('language') || 'es')

	React.useEffect(() => {
		if (!localStorage.getItem('language'))
			localStorage.setItem('language', 'es')
	}, [])

	const switchLanguage = React.useCallback(() => {
		const newLanguage = language === 'es' ? 'en' : 'es'

		localStorage.setItem('language', newLanguage)
		setLanguage(newLanguage)
	}, [language])

	return { language, switchLanguage }
}

export const useLanguage = () => {
	return useContext(languageContext)
}