import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import logoWhite from 'assets/icons/logo-white.png'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import facebook from 'assets/icons/facebook_red.png'
import instagram from 'assets/icons/instagram_red.png'

import { scrollTo } from 'assets/js/utils'
import { Link } from 'react-router-dom'
import { updateBubbles } from 'store/actions/bubbles'
import { useRoutes } from 'contexts/routesContext'
import { useText } from 'contexts/textContext'

const useLinkStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.red,
		textDecoration: 'none',
		cursor: 'pointer',
	},
}))

const MyLink = (props) => {
	const classes = useLinkStyles()

	return (
		<li style={{ cursor: 'pointer' }}>
			<Link {...props} className={classes.root}>
				{props.label}
			</Link>
		</li>
	)
}
MyLink.propTypes = {
	label: PropTypes.string,
	to: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.footer,
		color: theme.palette.red,
	},
	container: {
		padding: theme.spacing(1, 7, 4, 7),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 1, 2, 1),
		},
	},
	socialSection: {
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(3),
		},
	},
	bold: {
		fontWeight: 'bold',
	},
	logo: {
		height: '4rem',
	},
	logoContainer: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	noPadding: {
		padding: 8,
	},
	textField: {
		margin: 0,
		'& .MuiOutlinedInput-root': {
			color: theme.palette.red,
			borderRadius: 0,
			height: 32,
			border: `1px solid ${theme.palette.primary.light}`,
			'& fieldset, &:hover fieldset, &.Mui-focused fieldset': {
				border: 'transparent',
			},
		},
	},
	button: {
		color: theme.palette.secondary.main,
		borderRadius: 0,
		backgroundColor: theme.palette.primary.light,
		height: 32,
		textTransform: 'none',
		'&:hover': {
			backgroundColor: theme.palette.primary.light,
		},
		'& .MuiButton-label': {
			fontWeight: 'bold',
		},
	},
	ul: {
		listStyleType: 'none',
		'& > li': {
			fontSize: theme.typography.body2.fontSize,
		},
	},
	ulHeader: {
		fontWeight: 'bold',
	},
	borderTop: {
		borderTop: `1px solid ${theme.palette.red}`,
	},
	tiny: {
		fontSize: 9,
		color: theme.palette.red,
	},
	socialMedia: {
		height: '1rem',
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			height: '1.5rem',
			paddingLeft: theme.spacing(1),
		},
	},
	version: {
		fontSize: theme.typography.caption.fontSize,
		color: 'rgb(217,219,221)',
		position: 'absolute',
		userSelect: 'none',
		cursor: 'default',
		marginTop: -8,
		'&:hover': {
			color: theme.palette.gray,
		},
	},
	toTop: {
		textDecoration: 'underline',
		cursor: 'pointer',
		textTransform: 'uppercase',
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: theme.spacing(4),
		},
	},
}))

function Footer() {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { routesMap, footer } = useRoutes()
	const text = useText()

	const chunkedFooter = React.useMemo(() => {
		let newChuckedFooter = []

		const chunkSize = 4
		for (let i = 0; i < footer.length; i += chunkSize) {
			newChuckedFooter.push(footer.slice(i, i + chunkSize))
		}

		return newChuckedFooter
	}, [footer])

	const handleBubbles = React.useCallback(
		(bubble) => dispatch(updateBubbles(bubble)),
		[dispatch]
	)

	return (
		<div className={classes.root}>
			<Container maxWidth='md' className={classes.container}>
				<Grid container direction='column' justify='center' alignItems='center'>
					<Grid
						item
						onClick={() => {
							handleBubbles('#a')
							scrollTo('#a')
						}}
						style={{ cursor: 'pointer' }}
					>
						<ExpandLessIcon />
					</Grid>
					<Grid
						item
						onClick={() => {
							handleBubbles('#a')
							scrollTo('#a')
						}}
						className={classes.toTop}
					>
						{text.to_top}
					</Grid>
				</Grid>
				<Grid container justify='space-between' alignItems='center'>
					<Grid item md={3} className={classes.logoContainer}>
						<Link to='/'>
							<img src={logoWhite} className={classes.logo} />
						</Link>
						<div
							title={`${text.version} - ${process.env.REACT_APP_VERSION}`}
							className={classes.version}
						>
							{process.env.REACT_APP_VERSION}
						</div>
					</Grid>
					<Grid item md={9} container alignItems='flex-start'>
						{chunkedFooter.map((chunk, i) => (
							<Grid key={i} item xs={6} md={3}>
								<ul className={classes.ul}>
									{chunk.map((foot, j) => (
										<MyLink
											key={j}
											label={foot.name}
											to={routesMap[foot.pathKey]}
										/>
									))}
								</ul>
							</Grid>
						))}

						<Grid item xs={12} md={6} className={classes.socialSection}>
							<Grid container justify='flex-end'>
								<Grid item xs={12} className={classes.borderTop}></Grid>
								<Grid item>
									<Grid
										container
										alignItems='center'
										spacing={1}
										style={{ marginLeft: 'auto', paddingTop: 4 }}
									>
										<Grid item>
											<a
												target='_blank'
												rel='noopener noreferrer'
												href='https://www.instagram.com/citystoragerd'
											>
												<img src={instagram} className={classes.socialMedia} />
											</a>
										</Grid>
										<Grid item>
											<a
												target='_blank'
												rel='noopener noreferrer'
												href='https://www.facebook.com/citystoragerd'
											>
												<img
													type='submit'
													src={facebook}
													className={classes.socialMedia}
												/>
											</a>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} className={classes.tiny} style={{ textAlign: 'right' }}>
									{text.recaptcha[0]}{' '}
									<a href='https://policies.google.com/privacy' className={classes.tiny}>{text.recaptcha[1]}</a> {text.recaptcha[2]}{' '}
									<a href='https://policies.google.com/terms' className={classes.tiny}>{text.recaptcha[3]}</a> {text.recaptcha[4]}
									<br/>
									{`© ${new Date().getFullYear()} CityStorage | ${text.rights}`}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default Footer
