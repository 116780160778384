import React from 'react'
import { useHistory } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'

import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Container from '@material-ui/core/Container'
import Collapse from '@material-ui/core/Collapse'

import InputAdornment from '@material-ui/core/InputAdornment'
import EventIcon from '@material-ui/icons/Event'
import moment from 'moment'
import clsx from 'clsx'

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'

import ProviderButton from 'components/ProviderButton'
import { emailSignUp } from 'auth/auth'
import { useText } from 'contexts/textContext'

const useDateStyles = makeStyles((theme) => {
	return {
		root: {
			color: theme.palette.black,
			borderBottom: `1px solid ${theme.palette.black} !important`,
			overflow: 'hidden',
			borderRadius: 0,
			backgroundColor: 'transparent',
			'& .MuiInputBase-input': {
				padding: theme.spacing(1.2, 0),
				// fontFamily: theme.typography.body1.fontFamily,
				// fontWeight: theme.typography.body1.fontWeight,
				// fontSize: theme.typography.body1.fontSize,
				// color: theme.palette.white,
				'&::placeholder': {
					// fontFamily: theme.typography.body1.fontFamily,
					// fontWeight: theme.typography.body1.fontWeight,
					// fontSize: theme.typography.body1.fontSize,
					// color: theme.palette.white,
				},
			},
			'& .MuiOutlinedInput-root': {
				border: 0,
				'& fieldset': {
					border: 0,
				},
				'&:hover fieldset': {
					border: 0,
				},
				'&.Mui-focused fieldset': {
					backgroundColor: 'transparent',
					border: 0,
				},
			},
		},
	}
})

const useStyles = makeStyles((theme) => ({
	root: {
		// maxWidth: 512,
		// margin: 'auto',
		marginTop: 256,
	},
	container: {
		margin: 0,
		padding: theme.spacing(2),
		backgroundColor: theme.palette.white,
	},
	input: {
		borderBottom: `1px solid ${theme.palette.black}`,
		color: theme.palette.black,
		fontSize: theme.typography.body1.fontSize,
		backgroundColor: 'transparent',
		width: '100%',
		borderRadius: 0,
		padding: theme.spacing(1, 0),
	},
	sessionButton: {
		backgroundColor: theme.palette.blue,
		color: theme.palette.white,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(1),
		transition: '.3s',
		cursor: 'pointer',
		'&:hover': {
			transition: '.3s',
			backgroundColor: theme.palette.darkRed,
		},
	},
	sessionButtonDisabled: {
		backgroundColor: theme.palette.red,
		color: theme.palette.white,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(1),
		transition: '.3s',
		cursor: 'pointer',
		'&:hover': {
			transition: '.3s',
			backgroundColor: theme.palette.darkRed,
		},
	},
	createButton: {
		backgroundColor: 'transparent',
		color: theme.palette.black,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(1),
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	popper: {
		backgroundColor: theme.palette.red,
		padding: theme.spacing(2, 2.5),
	},
	dateInput: {
		fontWeight: 'normal',
		fontFamily: theme.typography.body1.fontFamily,
	},
}))

const credentialProps = {
	firstName: { default: '', required: true },
	lastName: { default: '', required: false },
	email: { default: '', required: true },
	dob: { default: null, required: false },
	phone: { default: '', required: false },
	cellphone: { default: '', required: false },
	password1: { default: '', required: true },
	password2: { default: '', required: true },
}

const initialCredentials = Object.keys(credentialProps).reduce(
	(obj, k) => ({ ...obj, [k]: credentialProps[k].default }),
	{}
)

const credentialsReducer = (state, action) => {
	if (action.type === 'dob')
		return {
			...state,
			[action.type]: action.payload,
		}

	const key = action.target.name
	const type = action.target.type
	let value = action.target.value

	switch (type) {
		case 'tel': {
			const x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
			value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
			break
		}
		case 'password': {
			value = value.trim()
		}
	}

	return {
		...state,
		[key]: value,
	}
}

function SignUp() {
	const history = useHistory()
	const dateClasses = useDateStyles()
	const classes = useStyles()
	const text = useText()

	const [errorMsg, setErrorMsg] = React.useState()
	const [signUpDisabled, setSignUpDisabled] = React.useState(false)
	const [credentials, dispatchCredentials] = React.useReducer(
		credentialsReducer,
		initialCredentials
	)
	const matches = useMediaQuery((theme) => theme.breakpoints.down('xs'))

	React.useEffect(() => {
		let newSignUpDisabled = false

		for (let key of Object.keys(credentialProps)) {
			let value = credentials[key]
			const defaultValue = credentialProps[key].default

			if (typeof defaultValue === 'string') value = value.trim()

			if (credentialProps[key].required && value === defaultValue) {
				newSignUpDisabled = true
				break
			}
		}

		setSignUpDisabled(newSignUpDisabled)
	}, [credentials])

	const handleErrorMsg = (msg) => {
		setErrorMsg(msg)

		setTimeout(() => {
			setErrorMsg()
		}, 7500)
	}

	const handleLogin = React.useCallback(
		(e) => {
			e.preventDefault()

			if (!signUpDisabled) {
				if (credentials.password1 !== credentials.password2) {
					handleErrorMsg(text.error_mismatching_passwords)
				} else {
					emailSignUp({
						email: credentials.email,
						password: credentials.password1,
					})
						.then(() => {
							history.push('/')
						})
						.catch((error) => {
							switch (error.code) {
								case 'auth/invalid-email':
									handleErrorMsg(text.error_invalid_email)
									break
								case 'auth/weak-password':
									handleErrorMsg(text.error_weak_password)
									break
								default:
									handleErrorMsg(text.error_generic)
							}
							console.log(error)
						})
				}
			}
		},
		[credentials, signUpDisabled, history, text]
	)

	return (
		<Container maxWidth='sm' className={classes.root}>
			<Grid container spacing={2} className={classes.container}>
				<Grid item xs={12} sm={6}>
					<form onSubmit={handleLogin}>
						<input
							className={classes.input}
							placeholder={`${text.name} *`}
							name='firstName'
							value={credentials.firstName}
							onChange={dispatchCredentials}
						/>
					</form>
				</Grid>
				<Grid item xs={12} sm={6}>
					<form onSubmit={handleLogin}>
						<input
							className={classes.input}
							placeholder={text.lastName}
							name='lastName'
							value={credentials.lastName}
							onChange={dispatchCredentials}
						/>
					</form>
				</Grid>
				<Grid item xs={12} sm={6}>
					<form onSubmit={handleLogin}>
						<input
							className={classes.input}
							placeholder={`${text.email} *`}
							type='email'
							name='email'
							value={credentials.email}
							onChange={dispatchCredentials}
						/>
					</form>
				</Grid>
				<Grid item xs={12} sm={6}>
					<form onSubmit={handleLogin}>
						{matches ? (
							<input
								className={classes.input}
								type='date'
								placeholder={text.dob}
								value={credentials.dob}
								onChange={(e) => {
									const newDate =
										e.target.value ?? moment(e.target.value).format('L')
									dispatchCredentials({ type: 'dob', payload: newDate })
								}}
							/>
						) : (
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									clearable={false}
									autoOk
									fullWidth
									placeholder={text.dob}
									okLabel={false}
									margin='none'
									format='MM/dd/yyyy'
									value={credentials.dob}
									// InputLabelProps={{ shrink: true }}
									name='dob'
									type='new-password'
									autoComplete='off'
									className={classes.dateInput}
									InputProps={{
										disableUnderline: true,
										classes: dateClasses,
										endAdornment: (
											<InputAdornment
												position='end'
												style={{ cursor: 'pointer' }}
											>
												<EventIcon />
											</InputAdornment>
										),
									}}
									onChange={(date) => {
										const newDate = date ?? moment(date).format('L')
										dispatchCredentials({ type: 'dob', payload: newDate })
									}}
								/>
							</MuiPickersUtilsProvider>
						)}
					</form>
				</Grid>
				<Grid item xs={12} sm={6}>
					<form onSubmit={handleLogin}>
						<input
							className={classes.input}
							placeholder={text.cel}
							type='tel'
							name='cellphone'
							value={credentials.cellphone}
							onChange={dispatchCredentials}
						/>
					</form>
				</Grid>
				<Grid item xs={12} sm={6}>
					<form onSubmit={handleLogin}>
						<input
							className={classes.input}
							placeholder={text.phone}
							type='tel'
							name='phone'
							value={credentials.phone}
							onChange={dispatchCredentials}
						/>
					</form>
				</Grid>
				<Grid item xs={12} sm={6}>
					<form onSubmit={handleLogin}>
						<input
							className={classes.input}
							placeholder={`${text.password} *`}
							type='password'
							name='password1'
							value={credentials.password1}
							onChange={dispatchCredentials}
						/>
					</form>
				</Grid>
				<Grid item xs={12} sm={6}>
					<form onSubmit={handleLogin}>
						<input
							className={classes.input}
							placeholder={`${text.password} *`}
							type='password'
							name='password2'
							value={credentials.password2}
							onChange={dispatchCredentials}
						/>
					</form>
				</Grid>

				<Grid item xs={12} sm={6}>
					<ProviderButton provider='Facebook' />
				</Grid>
				<Grid item xs={12} sm={6}>
					<ProviderButton provider='Google' />
				</Grid>
				<Grid item xs={6}></Grid>
				<Grid item xs={12} sm={6}>
					<button
						className={clsx(
							signUpDisabled
								? classes.sessionButtonDisabled
								: classes.sessionButton
						)}
						onClick={handleLogin}
						disabled={signUpDisabled}
					>
						{text.start_session}
					</button>
				</Grid>
			</Grid>
			<Collapse in={errorMsg}>
				<div className={classes.popper}>{errorMsg}</div>
			</Collapse>
		</Container>
	)
}

export default SignUp
