import React from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory, useLocation } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse'
import { makeStyles } from '@material-ui/core/styles'

import { emailSignIn } from 'auth/auth'
import { useRoutes } from 'contexts/routesContext'
import { useText } from 'contexts/textContext'
import ProviderButton from 'components/ProviderButton'

import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

const useStyles = makeStyles((theme) => ({
	input: {
		borderBottom: `1px solid ${theme.palette.black}`,
		backgroundColor: 'transparent',
		borderRadius: 0,
		width: '100%',
		padding: '.5rem 0',
	},
	socialButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		backgroundColor: 'transparent',
		padding: '.5rem',
		border: `1px solid ${theme.palette.gray}`,
		cursor: 'pointer',
	},
	sessionButton: {
		backgroundColor: theme.palette.red,
		color: theme.palette.white,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '.5rem',
		transition: '.3s',
		cursor: 'pointer',
		'&:hover': {
			transition: '.3s',
			backgroundColor: theme.palette.darkRed,
		},
	},
	createButton: {
		backgroundColor: 'transparent',
		color: theme.palette.black,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '.5rem',
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	popper: {
		backgroundColor: theme.palette.red,
		padding: theme.spacing(2, 2.5),
	},
	passwordIcon: {
		color: theme.palette.black,
	}
}))

const useStyleTextField = makeStyles((theme) => ({
	root: {
		color: `${theme.palette.black} !important`,
		fontSize: theme.typography.body1.fontSize,
		borderRadius: 0,
		backgroundColor: 'transparent',
		'& .MuiFormLabel-root': {
			'& .Mui-focused': {
				color: `${theme.palette.black} !important`,
			},
			'& .Mui-focused fieldset': {
				color: `${theme.palette.black} !important`,
			},
		},
		'& .MuiInput-underline:after': {
			borderBottom: `1px solid ${theme.palette.black} !important`,
		},
	},
}))

function CustomTextField(props) {
	const classes = useStyleTextField()

	return (
		<TextField
			{...props}
			fullWidth
			margin='none'
			classes={{ ...props.classes, root: classes.root }}
			InputProps={{ ...props.InputProps, classes }}
			InputLabelProps={{ ...props.InputLabelProps, classes, shrink: true }}
		/>
	)
}

CustomTextField.propTypes = {
	classes: PropTypes.any,
	InputProps: PropTypes.any,
	InputLabelProps: PropTypes.any,
}

function AuthNavMenu({ exit }) {
	const classes = useStyles()
	const history = useHistory()
	const location = useLocation()
	const { routesMap } = useRoutes()
	const text = useText()

	const [isPasswordVisible, setPasswordVisible] = React.useState(false)
	const [errorMsg, setErrorMsg] = React.useState()
	const [credentials, setCredentials] = React.useState({
		email: '',
		password: '',
	})

	const togglePasswordVisible = React.useCallback(
		() => setPasswordVisible(!isPasswordVisible),
		[isPasswordVisible]
	)

	const handleCredentials = React.useCallback(
		(type) => (e) => {
			setCredentials({ ...credentials, [type]: e.target.value })
		},
		[credentials]
	)

	const handleLogin = React.useCallback(
		(e) => {
			e.preventDefault()

			let cleanCredentials = { ...credentials }
			cleanCredentials['email'] = cleanCredentials.email.trim()

			exit()
			
			emailSignIn(cleanCredentials)
				.then(() => {
					if (['/crear-cuenta', '/signup'].includes(location.pathname))
						history.push('/')
				})
				.catch((error) => {
					switch (error.code) {
						case 'auth/user-not-found':
						case 'auth/wrong-password':
						case 'auth/invalid-email':
							setErrorMsg(text.error_incorrect_credentials)
							break
						default:
							console.log(error)
							setErrorMsg(text.error_generic)
					}
					setTimeout(() => {
						setErrorMsg()
					}, 7500)
					console.log(error)
				})
		},
		[credentials, exit, location, history, text]
	)

	return (
		<>
			<Grid
				container
				spacing={1}
				style={{
					margin: 0,
				}}
			>
				<Grid item xs={12}>
					<form onSubmit={handleLogin}>
						<CustomTextField
							label={text.email}
							type='email'
							value={credentials.email}
							onChange={handleCredentials('email')}
						/>
					</form>
				</Grid>
				<Grid item xs={12}>
					<form onSubmit={handleLogin}>
						<CustomTextField
							label={text.password}
							type={isPasswordVisible ? 'text' : 'password'}
							value={credentials.password}
							onChange={handleCredentials('password')}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton onClick={togglePasswordVisible}>
											{isPasswordVisible ? (
												<VisibilityOffIcon className={classes.passwordIcon} />
											) : (
												<VisibilityIcon className={classes.passwordIcon} />
											)}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</form>
				</Grid>
				<Grid item xs={12}>
					<ProviderButton provider='Facebook' />
				</Grid>
				<Grid item xs={12}>
					<ProviderButton provider='Google' />
				</Grid>
				<Grid item xs={6}>
					<button className={classes.sessionButton} onClick={handleLogin}>
						{text.start_session}
					</button>
				</Grid>
				<Grid item xs={6}>
					<Link to={routesMap['/signup']} onClick={exit}>
						<button className={classes.createButton}>
							{text.create_account}
						</button>
					</Link>
				</Grid>
			</Grid>
			<Collapse in={errorMsg}>
				<div className={classes.popper}>{errorMsg}</div>
			</Collapse>
		</>
	)
}

AuthNavMenu.propTypes = {
	exit: PropTypes.func.isRequired,
}

export default AuthNavMenu
