import React from 'react'

import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyleNumberTextField = makeStyles((theme) => ({
	root: {
		border: 0,
		overflow: 'hidden',
		borderRadius: 0,
		// backgroundColor: theme.palette.green,
		backgroundColor: 'transparent',
		'& .MuiOutlinedInput-root': {
			color: `${theme.palette.green} !important`,
			fontWeight: 'bold',
			textAlign: 'center',
			'& fieldset': {
				color: `${theme.palette.green} !important`,
				border: 0,
			},
			'&:hover fieldset': {
				border: 0,
			},
			'&.Mui-focused fieldset': {
				border: 0,
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: `${theme.spacing(1)}px !important`,
		},
	},
}))

function NumberTextField(props) {
	const classes = useStyleNumberTextField()

	return (
		<TextField
			{...props}
			fullWidth
			margin='dense'
			variant='outlined'
			disableClearable={true}
			classes={{ root: classes.root }}
			InputProps={{ classes }}
		/>
	)
}

export default NumberTextField