export const units = [3.5, 4.7, 5.9, 7.1, 10.6, 14, 28]
export const initialNewOption = {
	name: '',
	length: '',
	width: '',
	height: '',
	parent: 'custom',
}

export const isBad = (newOption) =>
	Object.keys(initialNewOption).some((k) => newOption[k]?.trim() === '') ||
	['length', 'width', 'height'].some(
		(k) => newOption[k]?.trim() !== '' && !parseFloat(newOption[k])
	)

export const getCurrentSize = (l) =>
	l.reduce((sum, list_val) => {
		const length = list_val.length || list_val.width
		const width = list_val.width || list_val.length

		return (
			sum + list_val.count * ((parseFloat(length) * parseFloat(width)) / 1550)
		)
	}, 0)

export const getCurrentUnit = (l) => {
	const currentSize = getCurrentSize(l)
	const fittedSize = units.filter((u) => currentSize < u)[0]

	if (fittedSize != null) return fittedSize
	return units[units.length - 1]
}